import { Box, Button, IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { RobotoTypo, TimesNewTypo } from "../../utils/Typographies";
import { ReactComponent as LeftArrow } from "../../assets/Home/News/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/Home/News/rightArrow.svg";
import { ReactComponent as Right } from "../../assets/Home/News/right.svg";
import { newsData } from "./newsData";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ReactComponent as Circle } from "../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  customDotList: {
    paddingTop: "70px",
    paddingBottom: "5px",
    "& .react-multi-carousel-dot-list": {
      bottom: "60px",
    },
  },
  leftArrow: {
    right: "12%",
  },
  rightArrow: {
    right: "8%",
  },

  "@media (max-width: 1600px)": {
    leftArrow: {
      right: "13%",
    },
  },
  "@media (max-width: 1200px)": {
    leftArrow: {
      right: "170px",
    },
    rightArrow: {
      right: "100px",
    },
  },
  "@media (max-width: 600px)": {
    leftArrow: {
      right: "47%",
      marginRight: "20px !important",
    },
    rightArrow: {
      left: "53%",
    },
    customDotList: {
      paddingTop: "88px",
    },
  },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#262D33",
    fontSize: "12px",
    color: "#F3F4F6",
    fontWeight: 400,
    borderRadius: "8px",
    padding: "12px",
  },
}));

const News = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [newsList, setNewsList] = useState([]);
  const Navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  const [data, setData] = useState([]);

  const getData = (data) => {
    console.log("news data ", data);
    const languageData = data?.map((item) => {
      return {
        data: language == "en" ? item.en : item.ch,
        id: item?._id,
      };
    });
    console.log("languageData ", languageData);
    setNewsList(languageData);
  };

  useEffect(() => {
    if (data != [] && data?.length != 0) {
      console.log("dashboardddata ", data);
      getData(data);
    }
  }, [language]);

  const customButtonStyle = {
    backgroundColor: "#177D84",
    "&:hover": {
      backgroundColor: "#177D84",
    },
    "&:active": {
      backgroundColor: "#177D84",
    },
  };

  const customLeftArrowStyle = {
    position: "absolute",
    top: "22px",
    border: "none",
    width: "43px",
    transform: "translateY(-50%)",
    backgroundColor: "#177D84",
    color: "white",
    padding: "8px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 1,
  };

  const customRightArrowStyle = {
    position: "absolute",
    top: "22px",
    border: "none",
    width: "43px",
    transform: "translateY(-50%)",
    backgroundColor: "#177D84",
    color: "white",
    padding: "8px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 1,
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType },
    } = rest;
    const carouselItems = [<Circle />, <Circle />, <Circle />, <Circle />];
    return (
      <button
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
      >
        {React.Children.toArray(carouselItems)[index]}
      </button>
    );
  };

  const getNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getNews`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          if (response?.data != "") {
            setData(response.data);
            getData(response.data);
          } else {
            setData([]);
          }
        } else if (
          typeof response?.data === "object" &&
          response?.data !== null
        ) {
          if (response?.data != "" && response?.data != null) {
            setData(response.data);
            getData(response.data);
          } else {
            setData([]);
          }
        }
      }
    } catch (error) {}
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-CA"); // 'en-CA' format is yyyy-mm-dd
  };

  useEffect(() => {
    getNews();
  }, []);
  console.log("news", newsList);
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        padding: "4% 0",
      }}
    >
      <Box
        sx={{
          borderBottomLeftRadius: { xs: 0, lg: "100px" },
          position: "absolute",
          right: 0,
          bottom: 0,
          top: 0,
          height: "90%",
          width: { xs: "100%", lg: "75%" },
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F5DFBA",
        }}
      ></Box>
      <Box
        sx={{
          width: "90%",
          display: "flex",
          position: "relative",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            position: "absolute",
            width: "100%",
            alignItems: { xs: "center", sm: "start" },
          }}
        >
          <RobotoTypo
            sx={{
              color: "#003B5C",
              marginBottom: { xs: "10px", sm: "0" },
              fontWeight: "700",
              fontSize: { xs: 24, md: 36 },
            }}
          >
            {t("Home.News.title")}
          </RobotoTypo>
        </Box>
        {/* {t('Home.News.NewsData', { returnObjects: true }).map((item, index) => (

      ))} */}
        <Box className={classes.customDotList}>
          <Carousel
            className={classes.customDotList}
            customRightArrow={
              <IconButton
                className={classes.rightArrow}
                style={{
                  ...customRightArrowStyle,
                }}
              >
                &gt;
              </IconButton>
            }
            customLeftArrow={
              <IconButton
                className={classes.leftArrow}
                style={{ ...customLeftArrowStyle }}
              >
                &lt;
              </IconButton>
            }
            swipeable={true}
            draggable={false}
            showDots={true}
            renderDotsOutside={<CustomDot />}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            // autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {newsList?.map((item, index) => (
              // <Box key={index} sx={{ display: 'flex', width: { sm: '35%', md: '25%', lg: '25%' }, gap: '15px', borderRadius: '50px', boxShadow: '4px 4px 4px 0px #00000040', padding: '24px', flexDirection: 'column', borderRadius: '24px', backgroundColor: '#FFFFFF' }}>
              //                     {t('Home.News.NewsData', { returnObjects: true }).map((item, index) => (

              // ))}
              <Box
                key={index}
                sx={{
                  display: "flex",
                  width: { sm: "70%", md: "70%", lg: "70%" },
                  gap: "15px",
                  borderRadius: "50px",
                  boxShadow: "4px 4px 4px 0px #00000040",
                  padding: "15px",
                  flexDirection: "column",
                  borderRadius: "24px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Box
                  component="img"
                  src={`${process.env.REACT_APP_FILE_URL}${item?.data?.image}`}
                  alt=""
                  sx={{ width: "100%", height: "200px", borderRadius: "25px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <RobotoTypo
                    sx={{
                      color: "#969696",
                      letterSpacing: "0.08em",
                      fontWeight: "700",
                      fontSize: { xs: 16, md: 16, lg: 18 },
                    }}
                  >
                    {formatDate(item?.data?.postedDate).replace(/-/g, ".")}
                  </RobotoTypo>
                  <Box>
                    <StyledTooltip
                      title={item?.data?.tagName}
                      arrow
                      placement="top"
                    >
                      <RobotoTypo
                        sx={{
                          color: "#177D84",
                          letterSpacing: "0.03em",
                          fontWeight: "700",
                          fontSize: { xs: 10, md: 12, lg: 15 },
                          border: "1px solid #177D84",
                          borderRadius: "50px",
                          padding: "4px 16px",
                          width: "100px",
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {item?.data?.tagName}{" "}
                      </RobotoTypo>
                    </StyledTooltip>
                  </Box>
                </Box>
                <StyledTooltip title={item?.data?.title} arrow placement="top">
                  <RobotoTypo
                    sx={{
                      color: "#003B5C",
                      letterSpacing: "0.08em",
                      fontWeight: "700",
                      fontSize: { xs: 18, sm: 18, md: 20, lg: 24 },
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.data?.title}{" "}
                  </RobotoTypo>
                </StyledTooltip>
                <Box sx={{ alignSelf: "flex-end" }}>
                  <RobotoTypo
                    onClick={() => Navigate(`/newsIndividualList/${item?.id}`)}
                    sx={{
                      cursor: "pointer",
                      borderRadius: "50px",
                      padding: "5px 15px",
                      backgroundColor: "#FFC424",
                      display: "inline-block",
                      color: "#FFFFFF",
                      letterSpacing: "0.05em",
                      lineHeight: "20px",
                      fontWeight: "700",
                      fontSize: { xs: 13, md: 14 },
                    }}
                  >
                    {t("Home.News.More_Button")}
                  </RobotoTypo>
                </Box>
              </Box>
            ))}
          </Carousel>
          {/* {newsData?.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', width: { sm: '35%', md: '25%', lg: '25%' }, gap: '15px', borderRadius: '50px', boxShadow: '4px 4px 4px 0px #00000040', padding: '24px', flexDirection: 'column', borderRadius: '24px', backgroundColor: '#FFFFFF' }}>
            <img src={item.image} alt="" borderRadius='25px' width='100%' height='auto' />
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <RobotoTypo sx={{ color: '#969696', letterSpacing: '0.08em', fontWeight: '700', fontSize: { xs: 16, md: 18, lg: 20 } }}>{item.date} </RobotoTypo>
              <Box>
                <RobotoTypo sx={{ color: '#177D84', letterSpacing: '0.03em', fontWeight: '700', fontSize: { xs: 10, md: 12, lg: 15 }, border: '1px solid #177D84', borderRadius: '50px', padding: '4px 16px' }}>{item.tagName} </RobotoTypo>
              </Box>

            </Box>
            <RobotoTypo sx={{ color: '#003B5C', letterSpacing: '0.08em', fontWeight: '700', fontSize: { xs: 18, sm: 18, md: 20, lg: 26 } }}>{item.title} </RobotoTypo>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <RobotoTypo sx={{ borderRadius: '50px', padding: '5px 15px', backgroundColor: '#FFC424', display: 'inline-block', color: '#FFFFFF', letterSpacing: '0.05em', lineHeight: '20px', fontWeight: '700', fontSize: { xs: 13, md: 14 } }}>MORE</RobotoTypo>
            </Box>
          </Box>
        ))} */}
        </Box>
        <Box   onClick={()=>Navigate("/news")} sx={{ textAlign: "center" }}>
          <Button
            disableElevation
            variant="contained"
            sx={{ mt: 5, borderRadius: "50px", textAlign: "center" }}
            style={customButtonStyle}
          >
            <Box
           
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",

                padding: "4px 16px",
              }}
            >
              <RobotoTypo
                sx={{
                  color: "#FFFFFF",
                  letterSpacing: "0.02em",
                  textTransform: "none",
                  lineHeight: "32px",
                  fontWeight: "700",
                  fontSize: { xs: 18, sm: 18, md: 22, lg: 24 },
                }}
              >
                {t("Home.News.LatestNewsButton")}
              </RobotoTypo>
              <Right width="20px" height="20px" />
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default News;
