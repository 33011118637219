import React, { useEffect, useState } from "react";
import Brand1 from "../../assets/png/brands/brand_1.png";
import Brand2 from "../../assets/png/brands/brand_2.png";
import Brand3 from "../../assets/png/brands/brand_3.png";
import Brand4 from "../../assets/png/brands/brand_4.png";
import Brand5 from "../../assets/png/brands/brand_5.png";
import Brand6 from "../../assets/png/brands/brand_6.png";
// import FlowDiagram from '../../assets/Home/flowDiagram.png';
import FlowDiagram from "../../assets/Home/Singapore flowchart.png";
import { Box, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RobotoTypo } from "../../utils/Typographies";
import ReactImageMagnify from "react-image-magnify";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";

const BrandImages = [Brand3, Brand5, Brand4, Brand1, Brand2, Brand6];

const FlowChart = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [position, setPosition] = useState(
    sessionStorage?.getItem("position") ?? []
  );
  const [back, setBack] = useState(
    sessionStorage.getItem("back") ? true : false
  );
  const handleClickImage = (e) => {
    const x = e.clientX + window.scrollX; // Capture the x position with current scroll
    const y = window.scrollY; // Capture the y position with current scroll
    setPosition([x, y]);
    sessionStorage.setItem("position", [x, y]);
    navigate("/flowChart");
  };

  useEffect(() => {
    setBack(sessionStorage.getItem("back"));
  }, []);

  useEffect(() => {
    if (position && back) {
      console.log("back ", back);
      window.scrollTo({
        top: position.split(",")[1],
        left: position.split(",")[0],
        behavior: "smooth", // Smooth scroll animation
      });
      sessionStorage.removeItem("position");
      sessionStorage.removeItem("back");
      setBack(false);
      setPosition([]);
    }
  }, [position, back]);

  return (
    <Box sx={{ p: { xs: 4, lg: 6 } }}>
      <RobotoTypo
        sx={{
          textAlign: "center",
          fontSize: { xs: 20, sm: 24, lg: 32 },
          color: "#003B5C",
          fontWeight: 700,
        }}
      >
        {t("Home.flowChart.title")}
      </RobotoTypo>
      <Box
        component="img"
        id="image"
        src={FlowDiagram}
        alt={`singapore-flow-diagram`}
        sx={{
          cursor: "pointer",
          height: { xs: "300px", sm: "500px" },
          width: "100%",
          objectFit: "contain",
        }}
        onClick={handleClickImage}
      />
    </Box>
  );
};

export default FlowChart;
