import React, { useState } from 'react'
import { Box, Divider, TextField, Select, FormControl, MenuItem, InputLabel, Button, Grid, TextareaAutosize, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { RobotoTypo, ErrTypo } from '../../../utils/Typographies'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../pages/Admin/News/News.css';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'


export default function UniversityApplicationForm1({setIsFormValid, setFormPage, personalDetails, setPersonalDetails,form1Errors, setForm1Errors, validationForm1 }) {
    
    const handleChange =(e, reqField) => {
        let name;
        let value;
        if(reqField != 'dob' && reqField != 'course_start_date' ) {
            name = e.target.name
            value = e.target.value
        } 
        if(name =='name' || name =='relation' || name == 'addressAndPostal' || name == 'phoneNumber') {
            if(name == 'phoneNumber') {
                if (/^\d*$/.test(value)) {
                    setPersonalDetails(prev => ({
                        ...prev,
                        emergency_contact: {
                            ...prev.emergency_contact,
                            [name]: value
                        }
                    }));
                }
            } else {
                setPersonalDetails(prev => ({...prev, emergency_contact:{
                    ...prev.emergency_contact, [name]: value
                }}))
            }
            
        } else if(reqField=='dob' || reqField == 'course_start_date') { 
            const formattedDate = moment(e).format('DD/MM/YYYY')
            console.log('formattedDate ',formattedDate)
            setPersonalDetails(prev => ({...prev, [reqField]: formattedDate}))
            if(reqField == 'dob')  setForm1Errors(prev => ({...prev, [reqField] : ""}))
        } else {
            if(name == 'mobile_number') {
                if (/^\d*$/.test(value)) {
                    setPersonalDetails(prev => ({...prev, [name]: value}))
                    setForm1Errors(prev => ({...prev, [name] : ""}))
                }
            } else {
                setPersonalDetails(prev => ({...prev, [name]: value}))
                setForm1Errors(prev => ({...prev, [name] : ""}))
            }
        

        }
    }   

    const handleMobileInput = (value, country, reqField,format,e) => {
        console.log('country ',country)
        if(reqField == 'mobile_number') {
            setPersonalDetails(prev => ({...prev, [reqField]: e}))
            setPersonalDetails(prev => ({...prev, student_country_code: `${country.format[0]}${country.dialCode}`}))
            setForm1Errors(prev => ({...prev, [reqField] : ""}))
        } else if(reqField == 'phoneNumber') {
            setPersonalDetails(prev => ({
                ...prev,
                emergency_contact: {
                    ...prev.emergency_contact,
                    [reqField]: e
                }
            }));

        }


    }

    const handleContinue = () => {
        const isValid = validationForm1()
        // console.log('isValid ',isValid)
        // if(!isValid) {
            setIsFormValid(prev => ({...prev, formOne: true}))
            setFormPage(1)
        // }

    }
    
    const { t } = useTranslation();
    return (
        <>
            <Box sx={{ backgroundColor: "#F7F7F7", margin: { md: '0 8%', xs: '0 2%' }, borderBottomLeftRadius: '20px', borderBottomRightRadius:'20px' }}>
            <Divider
                    sx={{
                        borderTop: "1px solid #F3F4F6",
                        width: "100%",
                    }}
                />
                <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                    <Grid xs={12} md={5} >
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.student_name', { returnObjects: true })} 
                        </RobotoTypo>
                        <TextField name="student_name" value={personalDetails.student_name} onChange={handleChange} />
                        {form1Errors?.student_name !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.student_name} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.dob', { returnObjects: true })}
                        </RobotoTypo>
                        {/* <TextField name='dob' value={personalDetails.dob} onChange={handleChange} /> */}
                        <Box sx={{width:'100%', display:'flex',flex:1}}>
                            <DatePicker
                             dateFormat="dd/MM/yyyy"
                            style={{width:'100%'}}
                            selected={personalDetails?.dob ? moment(personalDetails.dob, 'DD/MM/YYYY').toDate() : null}
                            onChange={(e) => handleChange(e, 'dob')}
                            placeholderText="DD/MM/YYYY"
                            customInput={<TextField name='dob' sx={{width:'100%'}} fullWidth />}
                            />
                        </Box>
                        {form1Errors?.dob !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.dob} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.nationality', { returnObjects: true })}
                        </RobotoTypo>
                        <TextField name='nationality' value={personalDetails.nationality} onChange={handleChange} />
                        {form1Errors?.nationality !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.nationality} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.email', { returnObjects: true })} 
                        </RobotoTypo>
                        <TextField name='email' value={personalDetails.email} onChange={handleChange} />
                        {form1Errors?.email !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.email} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.documents_held', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                        </RobotoTypo>
                        <TextField name='documents_held' value={personalDetails.documents_held} onChange={handleChange} />
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.passport_no', { returnObjects: true })} 
                        </RobotoTypo>
                        <TextField name='passport_number' value={personalDetails.passport_number} onChange={handleChange} />
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.have_you_studied', { returnObjects: true })}
                        </RobotoTypo>
                        <TextField name='have_you_studied' value={personalDetails.have_you_studied} onChange={handleChange} />
                        {form1Errors?.have_you_studied !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.have_you_studied} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.selected_school', { returnObjects: true })} 
                        </RobotoTypo>
                        <TextField name='selected_school' value={personalDetails.selected_school} onChange={handleChange} />
                        {form1Errors?.selected_school !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.selected_school} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo mb={1} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.academic_level', { returnObjects: true })}
                        </RobotoTypo>
                        <TextField name='academic_level' value={personalDetails.academic_level} onChange={handleChange} />
                        {form1Errors?.academic_level !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.academic_level} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.course_nature', { returnObjects: true })} 
                        </RobotoTypo>
                        <RadioGroup row name="course_nature" value={personalDetails.course_nature} onChange={handleChange}>
                            <FormControlLabel
                             sx={{  color: '#003B5C', fontSize: '14px', ml:0, mt:1  }}
                                value='Full Time'
                                control={<Radio  name="course_nature" />}
                                label={t('UniversityApplicationForm.full_time', { returnObjects: true })}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                              sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Part time'
                                control={<Radio  name="course_nature" />}
                                label={t('UniversityApplicationForm.part_time', { returnObjects: true })}
                                labelPlacement="top"
                            />
                        </RadioGroup>
                        {form1Errors?.course_nature !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.course_nature} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.courses_enrolled', { returnObjects: true })} 
                        </RobotoTypo>
                        <TextField name='courses_enrolled' value={personalDetails.courses_enrolled} onChange={handleChange} />
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.course_start_date', { returnObjects: true })} 
                        </RobotoTypo>
                        {/* <TextField name='course_start_date' value={personalDetails.course_start_date} onChange={handleChange} /> */}
                        <Box sx={{width:'100%', display:'flex',flex:1}}>
                            <DatePicker
                             dateFormat="dd/MM/yyyy"
                            style={{width:'100%'}}
                            selected={personalDetails.course_start_date ? moment(personalDetails.course_start_date, 'DD/MM/YYYY').toDate() : null}
                            onChange={(e) => handleChange(e, 'course_start_date')}
                            placeholderText="DD/MM/YYYY"
                            customInput={<TextField name='dob' sx={{width:'100%'}} fullWidth />}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.mobile_phone_number', { returnObjects: true })} 
                        </RobotoTypo>
                        {/* <TextField name='mobile_number' value={personalDetails.mobile_number} onChange={handleChange} /> */}
                        <PhoneInput value={personalDetails.mobile_number}
                        onChange={(value, country, format, event) => handleMobileInput(value, country,'mobile_number', format, event)}
                        // inputProps={{ name: "phone", maxLength: 20 }}
                        country={"sg"}
                        buttonStyle={{ border: "none" }}
                        inputStyle={{
                            height: "54px",
                            width: "100%",
                            marginTop: '10px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 4px 0px #00000040 inset',
                            "& fieldset": { border: "none", },
                            "& .MuiInputBase-input": {
                                padding: '14px 14px',
                            },
                            // "&>.react-tel-input .flag-dropdown ": {
                            //     backgroundColor: 'white',
                            // }
                        }}
                        countryCodeEditable={false} />
                        {form1Errors?.mobile_number !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.mobile_number} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12}  md={5}>
                        <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.birth_certificate_number', { returnObjects: true })} 
                        </RobotoTypo>
                        <TextField name='birth_certificateNo' value={personalDetails.birth_certificateNo} onChange={handleChange} />
                        {form1Errors?.birth_certificateNo !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.birth_certificateNo} </ErrTypo> </Box>}
                    </Grid>
                    <Grid xs={12} md={5}>
                        <RobotoTypo mb={2} sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.student_title', { returnObjects: true })}
                        </RobotoTypo>
                        <RadioGroup row  name="student_title" value={personalDetails.student_title} onChange={handleChange}>
                            <FormControlLabel
                                sx={{ml:0,color: '#003B5C', fontSize: '14px',}}
                                value='Mr'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.mr', { returnObjects: true })}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Miss'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.miss', { returnObjects: true })}
                                labelPlacement="top"
                            />
                              <FormControlLabel
                                sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Mrs'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.mrs', { returnObjects: true })}
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                sx={{  color: '#003B5C', fontSize: '14px',  }}
                                value='Ms'
                                control={<Radio  name="student_title"/>}
                                label={t('UniversityApplicationForm.ms', { returnObjects: true })}
                                labelPlacement="top"
                            />
                        </RadioGroup>
                        {form1Errors?.student_title !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.student_title} </ErrTypo> </Box>}
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.marriage_status', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField name='marraige_status' value={personalDetails.marraige_status} onChange={handleChange} />
                        </Grid>

                        <Grid xs={12}>
                            <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.home_address', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField name='home_address' value={personalDetails.home_address} onChange={handleChange} sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                                multiline
                            />
                            {form1Errors?.home_address !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form1Errors?.home_address} </ErrTypo> </Box>}
                        </Grid>

                        <Grid xs={12}>
                            <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.singapore_address', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                            </RobotoTypo>
                            <TextField name='singapore_address' value={personalDetails.singapore_address} onChange={handleChange} sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                                multiline
                            />
                        </Grid>

                        <Grid xs={12}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.emergency_contact', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                            </RobotoTypo>
                            
                            
                        </Grid>

                        <Grid xs={12} md={5}>
                            <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.name', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                            </RobotoTypo>
                            <TextField name='name' value={personalDetails.emergency_contact.name} onChange={handleChange} />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.relation', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                            </RobotoTypo>
                            <TextField name='relation' value={personalDetails.emergency_contact.relation} onChange={handleChange} />
                        </Grid>
                        <Grid xs={12}>
                            <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.address_postal', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                            </RobotoTypo>
                            <TextField name='addressAndPostal' value={personalDetails.emergency_contact.addressAndPostal} onChange={handleChange} sx={{ '& .MuiInputBase-root': { padding: '0px' }, minHeight: '88px' }}
                                multiline
                            />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.phone_number', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                            </RobotoTypo>
                            <PhoneInput value={personalDetails.emergency_contact.phoneNumber}
                        onChange={(value, country, format,event) => handleMobileInput(value, country,'phoneNumber', format, event)}
                        // inputProps={{ name: "phone", maxLength: 20 }}
                        country={"sg"}
                        buttonStyle={{ border: "none" }}
                        inputStyle={{
                            height: "54px",
                            width: "100%",
                            marginTop: '10px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 4px 0px #00000040 inset',
                            "& fieldset": { border: "none", },
                            "& .MuiInputBase-input": {
                                padding: '14px 14px',
                            },
                            // "&>.react-tel-input .flag-dropdown ": {
                            //     backgroundColor: 'white',
                            // }
                        }}
                        countryCodeEditable={false} />
                            {/* <TextField
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            name='phoneNumber' value={personalDetails.emergency_contact.phoneNumber} onChange={handleChange} /> */}
                        </Grid>
                    
                </Grid>
                <Box display='flex' justifyContent="end" padding='2% 3%' sx={{ borderTop: "1px solid #E5E7EB", }} >
                    <Button onClick={handleContinue} sx={{}} variant='Standard'>Continue</Button>
                </Box>
            </Box>
        </>
    )
}
