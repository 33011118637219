import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Zoom,
  CircularProgress,
} from "@mui/material";
import { Suspense, useState } from "react";
import Aboutus from "./pages/Aboutus";
import StudyingAboard from "./pages/StudyingAboard";
import PrimarySchool from "./components/StudyingAboard/EducationalPages/PrimarySchool/PrimarySchool";
import HighSchool from "./components/StudyingAboard/EducationalPages/HighSchool/HighSchool";
import InternationalSchool from "./components/StudyingAboard/EducationalPages/InternationalSchool/InternationalSchool";
import University from "./components/StudyingAboard/EducationalPages/University/University";
import ContactUs from "./pages/ContactUs";
import StudentPassApplication from "./pages/StudentPassApplication";
import LongTermPass from "./pages/LongTermPass";
import CertificationOfQualification from "./pages/CertificationOfQualification";
import VaccineCertification from "./pages/VaccineCertification";
import TravelVisa from "./pages/TravelVisa";
import EndrollmentApplication from "./pages/EndrollmentApplication";
import DrApp from "./pages/DrApp";
import TransferConsultation from "./pages/TransferConsultation";
import InvestmentConsultation from "./pages/InvestmentConsultation";
import ImmigrationConsultation from "./pages/immigrationConsultation";
import PropertyPurchase from "./pages/PropertyPurchase";
import JobSeeking from "./pages/JobSeeking";
import GuardianService from "./pages/GuardianService";
import CompanyRegistration from "./pages/CompanyRegistration";
import LandingService from "./pages/landingService";
import Dormitory from "./pages/Dormitory";
import RentingConsultation from "./pages/RentingConsultation";
import SideOverlap from "./pages/sideOverlap";
import SchoolInfo from "./pages/SchoolInfo";
import AdminLogin from "./pages/Admin/AdminLogin";
import Carousel from "./pages/Admin/Mainpage/Carousel";
import CarouselList from "./pages/Admin/Mainpage/CarouselList";
import SchoolList from "./pages/Admin/School/SchoolList";
import School from "./pages/Admin/School/School";
import AirportPickup from "./pages/AirportPickup";
import EnglishTrailClass from "./pages/EnglishTrailClass";
import AcademicCounseling from "./pages/AcademicCounseling";
import AeisExamPre from "./pages/AeisExamPre";
import AeisExam from "./pages/AeisExam";
import AeisExamFeesInfo from "./pages/AeisExamFeesInfo";
import OLevelPreCourse from "./pages/OLevelPreCourse";
import ALevelPreCourse from "./pages/ALevelPreCourse";
import EndrollmentTraining from "./pages/EnrollmentTraining";
import InternationalEnrollment from "./pages/InternationalEnrollment";
import ExtraCurricularActivity from "./pages/ExtraCurricular";
import SchoolBasedTour from "./pages/SchoolBasedTour";
import SelfGuidedTour from "./pages/SelfGuidedTour";
import Payment from "./pages/Payment";
import Tution from "./pages/Tution";
import TutionCourseInformation from "./pages/TutionCourseInformation";
import UniversityTution from "./pages/UniversityTution";
import EducationalJourney from "./pages/EducationalJourney";
import RentingApplication from "./pages/RentingApplication";
import AcademicAssesment from "./pages/AcademicAssesment";
import Register from "./pages/Register";
import News from "./pages/Admin/News/News";
import NewsList from "./pages/Admin/News/NewsList";
import { PrivateRoutes, PrivateRoute, LoginRoute } from "./utils/common";
import TeamList from "./pages/Admin/Team/TeamList";
import Teams from "./pages/Admin/Team/Teams";
import Psychology from "./pages/Psychology";
import SchoolApplication from "./pages/SchoolApplication";
import StudentList from "./pages/Admin/Students/StudentList";
import UniversityApplication from "./pages/UniversityApplication";
import Otp from "./pages/Otp";
import UserLogin from "./pages/UserLogin";
import OurTeam from "./pages/Admin/Team/OurTeam";
import OurTeamList from "./pages/Admin/Team/OurTeamList";
import RouteChangeHandler from "./RouteChangeHandler";
import AppliedSchoolsList from "./components/ApplicationForm/AppliedSchoolsList";
import ScrollToTop from "./components/scrollUp/ScrollUpComponent";

import NewsComponent from "./pages/News/NewsComponent";
import AdminExtraCurricularActivities from "./pages/Admin/Services/AdminExtraCurricularActivities";
import AddExtraCurricularActivities from "./pages/Admin/Services/AddExtraCurricularActivities";
import LanguageCourse from "./components/StudyingAboard/LanguageCourse/LanguageCourse";
import ChildCare from "./components/StudyingAboard/ChildCare/ChildCare";
import NewsIndividualList from "./pages/News/NewsIndividualList";
import AddHigherOfficial from "./pages/Admin/Team/AddHigherOfficial";
import HigherOfficial from "./pages/Admin/Team/HigherOfficial";
import EnrollmentApplication from "./pages/Admin/Services/enrollmentApplication/EnrollmentApplication";
import AddEnrollmentApplication from "./pages/Admin/Services/enrollmentApplication/AddEnrollmentApplication";
import ZoomImageFile from "./pages/ZoomImageFile";

function App() {
  const role_name = localStorage.getItem("role_name");
  const [userDetails, setUserDetails] = useState({});
  console.log("userDetails ", userDetails);

  return (
    <Box>
      <BrowserRouter>
        <RouteChangeHandler />
        <ScrollToTop />
        {/* {(window.location.pathname != '/admin/login' && window.location.pathname != '/admin/admindashboard') && <SideOverlap />} */}
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/studyingAboard" element={<StudyingAboard />} />
          <Route path="/studentHostels" element={<Dormitory />} />
          <Route path="/flowChart" element={<ZoomImageFile />} />
          <Route path="/news" element={<NewsComponent />} />
          <Route
            path="/newsIndividualList/:id"
            element={<NewsIndividualList />}
          />
          <Route
            path="/studyingAboard/primarySchool"
            element={<PrimarySchool />}
          />
          <Route path="/studyingAboard/highSchool" element={<HighSchool />} />
          <Route
            path="/studyingAboard/internationalSchool"
            element={<InternationalSchool />}
          />
          <Route
            path="/studyingAboard/languageCourse"
            element={<LanguageCourse />}
          />
          <Route path="/studyingAboard/childCare" element={<ChildCare />} />
          <Route path="/studyingAboard/university" element={<University />} />
          <Route
            path="/studyingAboard/schoolsApplied"
            element={<AppliedSchoolsList />}
          />
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/services/studentpassapplication"
            element={<StudentPassApplication />}
          />
          <Route path="/services/longtermpass" element={<LongTermPass />} />
          <Route
            path="/services/qualificationcertificate"
            element={<CertificationOfQualification />}
          />
          <Route
            path="/services/vaccinecertification"
            element={<VaccineCertification />}
          />
          <Route path="/services/travelvisa" element={<TravelVisa />} />
          <Route
            path="/services/endrollmentapplication"
            element={<EndrollmentApplication />}
          />
          <Route path="/services/drapp" element={<DrApp />} />
          <Route
            path="/services/transferconsultation"
            element={<TransferConsultation />}
          />
          <Route
            path="/services/investmentconsultation"
            element={<InvestmentConsultation />}
          />
          <Route
            path="/services/immigrationconsultation"
            element={<ImmigrationConsultation />}
          />
          <Route
            path="/services/propertypurchase"
            element={<PropertyPurchase />}
          />
          <Route path="/services/jobseeking" element={<JobSeeking />} />
          <Route
            path="/services/guardianservice"
            element={<GuardianService />}
          />
          <Route
            path="/services/companyregistration"
            element={<CompanyRegistration />}
          />
          <Route path="/services/landingService" element={<LandingService />} />
          <Route path="/studentHostel" element={<Dormitory />} />
          <Route
            path="/services/rentingconsultation"
            element={<RentingConsultation />}
          />
          <Route path="/airportpickup" element={<AirportPickup />} />
          <Route
            path="/services/englishtrailclass"
            element={<EnglishTrailClass />}
          />
          <Route
            path="/services/academiccounseling"
            element={<AcademicCounseling />}
          />
          <Route path="/services/aeispreexam" element={<AeisExamPre />} />
          <Route path="/services/aeis" element={<AeisExam />} />
          <Route
            path="/services/aeisprecoursefees"
            element={<AeisExamFeesInfo />}
          />
          <Route
            path="/services/olevelprecourse"
            element={<OLevelPreCourse />}
          />
          <Route
            path="/services/alevelprecourse"
            element={<ALevelPreCourse />}
          />
          <Route
            path="/services/enrollmenttraining"
            element={<EndrollmentTraining />}
          />
          <Route
            path="/enrollmentTrainingCourseInformation"
            element={<InternationalEnrollment />}
          />
          <Route
            path="/services/extracurricularactivity"
            element={<ExtraCurricularActivity />}
          />
          <Route
            path="/services/schoolbasedtour"
            element={<SchoolBasedTour />}
          />
          <Route path="/services/selfguidedtour" element={<SelfGuidedTour />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/services/tution" element={<Tution />} />
          <Route
            path="/services/TutionCourseInfo"
            element={<TutionCourseInformation />}
          />
          <Route
            path="/services/universitytution"
            element={<UniversityTution />}
          />
          <Route
            path="/services/educationaljourney"
            element={<EducationalJourney />}
          />
          <Route
            path="/services/rentingapplication"
            element={<RentingApplication />}
          />
          <Route
            path="/services/academicassesment"
            element={<AcademicAssesment />}
          />
          <Route
            path="/register"
            element={
              <Register
                userDetails={userDetails}
                setUserDetails={setUserDetails}
              />
            }
          />
          <Route path="/Otp" element={<Otp userDetails={userDetails} />} />
          <Route element={<LoginRoute />}>
            <Route
              path="/login"
              element={
                <UserLogin
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                />
              }
            />
          </Route>

          <Route path="/school/:Slug/:id" element={<Psychology />} />
          <Route path="/school/:schoolId" element={<SchoolInfo />} />
          {/* <Route patgh='/school/:id/SchoolApplicationForm' element={<SchoolApplication />} /> */}
          <Route
            path="/school/:id/SchoolApplicationForm"
            element={<SchoolApplication />}
          />
          <Route
            path="/school/:id/universityApplication"
            element={<UniversityApplication />}
          />
          <Route element={<PrivateRoute />}>
            <Route path="/admin/login" element={<AdminLogin />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/admin/Carousel/:carouselId" element={<Carousel />} />
            <Route path="/admin/Carousel/add" element={<Carousel />} />
            <Route path="/admin/Carousel" element={<CarouselList />} />
            <Route path="/admin/primarySchool" element={<SchoolList />} />
            <Route path="/admin/secondarySchool" element={<SchoolList />} />
            <Route path="/admin/languageCourse" element={<SchoolList />} />
            <Route path="/admin/kindergarden" element={<SchoolList />} />
            <Route path="/admin/internationalSchool" element={<SchoolList />} />
            <Route path="/admin/university" element={<SchoolList />} />
            <Route path="/admin/languageCourse/add" element={<School />} />
            <Route path="/admin/kindergarden/add" element={<School />} />
            <Route path="/admin/primarySchool/add" element={<School />} />
            <Route path="/admin/secondarySchool/add" element={<School />} />
            <Route path="/admin/internationalSchool/add" element={<School />} />
            <Route path="/admin/university/add" element={<School />} />
            <Route path="/admin/primarySchool/:schoolId" element={<School />} />
            <Route
              path="/admin/secondarySchool/:schoolId"
              element={<School />}
            />
            <Route
              path="/admin/languageCourse/:schoolId"
              element={<School />}
            />
            <Route path="/admin/kindergarden/:schoolId" element={<School />} />
            <Route
              path="/admin/internationalSchool/:schoolId"
              element={<School />}
            />
            <Route path="/admin/university/:schoolId" element={<School />} />
            <Route path="/admin/news/add" element={<News />} />
            <Route path="/admin/news/:newsId" element={<News />} />
            <Route path="/admin/news" element={<NewsList />} />
            <Route path="/admin/singaporeTeam" element={<TeamList />} />
            <Route path="/admin/higherOfficial" element={<HigherOfficial />} />
            <Route
              path="/admin/addHigherOfficial"
              element={<AddHigherOfficial />}
            />
            <Route
              path="/admin/EditHigherOfficial/:id"
              element={<AddHigherOfficial />}
            />
            <Route path="/admin/taiwanTeam" element={<TeamList />} />
            <Route path="/admin/hongKongTeam" element={<TeamList />} />
            <Route path="/admin/chinaTeam" element={<TeamList />} />
            <Route path="/admin/singaporeTeam" element={<TeamList />} />
            <Route path="/admin/taiwanTeam" element={<TeamList />} />
            <Route path="/admin/hongKongTeam" element={<TeamList />} />
            <Route path="/admin/chinaTeam" element={<TeamList />} />
            <Route path="/admin/ourTeamList" element={<OurTeamList />} />
            <Route
              path="/admin/enrollmentApplication"
              element={<EnrollmentApplication />}
            />
            <Route
              path="/admin/addEnrollmentApplication"
              element={<AddEnrollmentApplication />}
            />
            <Route
              path="/admin/updateEnrollmentApplication/:id"
              element={<AddEnrollmentApplication />}
            />
            <Route path="/admin/singaporeTeam/add" element={<Teams />} />
            <Route path="/admin/taiwanTeam/add" element={<Teams />} />
            <Route path="/admin/hongKongTeam/add" element={<Teams />} />
            <Route path="/admin/chinaTeam/add" element={<Teams />} />
            <Route path="/admin/ourTeam/add" element={<OurTeam />} />

            <Route path="/admin/singaporeTeam/:teamId" element={<Teams />} />
            <Route path="/admin/taiwanTeam/:teamId" element={<Teams />} />
            <Route path="/admin/hongKongTeam/:teamId" element={<Teams />} />
            <Route path="/admin/chinaTeam/:teamId" element={<Teams />} />
            <Route path="/admin/ourTeam/:teamMateId" element={<OurTeam />} />
            <Route path="/admin/StudentList" element={<StudentList />} />
            <Route
              path="/admin/ExtraCurricularActivities"
              element={<AdminExtraCurricularActivities />}
            />
            <Route
              path="/admin/AddExtraCurricularActivities/:id"
              element={<AddExtraCurricularActivities />}
            />
            <Route
              path="/admin/AddExtraCurricularActivities/add"
              element={<AddExtraCurricularActivities />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default function WrappedApp() {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: to add a background overlay
            zIndex: 9999, // Make sure it's on top of other elements
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <App />
    </Suspense>
  );
}
