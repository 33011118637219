import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { Clear } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

const rows = [
  { id: 1, title: "Title 1", description: "Description 1" },
  { id: 2, title: "Title 2", description: "Description 2" },
  { id: 3, title: "Title 3", description: "Description 3" },
  // Add more rows as needed
];

export default function SchoolList() {
  const navigate = useNavigate();
  const [schoolList, setSchoolList] = useState([]);
  const location = useLocation();
  const schoolType = location?.pathname.replace("/admin/", "");

  const getSchoolByType = () => {
    const obj = {
      primarySchool: "getPrimarySchools",
      secondarySchool: "getSecondarySchools",
      internationalSchool: "getInternationalSchools",
      university: "getUniversities",
      languageCourse: "getLanguageCourse",
      kindergarden:"getKindergarden"
    };
    return obj[schoolType];
  };

  const getSchools = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${getSchoolByType()}`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          setSchoolList(response?.data != "" ? response?.data : []);
        } else if (
          typeof response?.data === "object" &&
          response?.data !== null
        ) {
          setSchoolList(response?.data != "" ? [response?.data] : []);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    setSchoolList([]);
    getSchools();
  }, [schoolType]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteSchoolById/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        getSchools();
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };
  const handleEdit = (id) => {
    navigate(`/admin/${schoolType}/` + id);
  };

  return (
    <Admin>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 1,
        }}
      >
        <Button
          component="span"
          onClick={() => navigate(`/admin/${schoolType}/add`)}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          Add
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schoolList?.length != 0 &&
              schoolList?.map((school, index) => (
                <TableRow key={school.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{school.en.school_name}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(school._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(school._id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
}
