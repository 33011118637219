import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import flow from "../assets/Home/Singapore flowchart.png";
import { Box, IconButton } from "@mui/material";
import InnerImageZoom from "react-inner-image-zoom";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
const ZoomImageFile = () => {
  const useStyles = makeStyles({
    iiz__img: {
      maxWidth: " 100%",
      height: "100vh",
      display: "block",
      pointerEvents: "none",
      visibility: "visible",
      opacity: 1,
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const offset = 1050; // Adjust this value as needed

  const handleClose = () => {
    sessionStorage.setItem("back",true)
    navigate("/");
  };

  return (
    <Box
      display="flex"
      height="100vh"
      width="100%"
      sx={{ background: "#f0f0f0", position: "relative" }}
      justifyContent="center"
      alignItems="center"
    >
      {/* Close Icon */}
      <IconButton
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          zIndex: 10, // Ensure the icon is above the image
          "& .MuiSvgIcon-root": {
            fontSize: 30, // Custom size in pixels (adjust as needed)
          },
        }}
        onClick={handleClose}
      >
        <Close />
      </IconButton>

      {/* Image Zoom */}
      <InnerImageZoom
        style={{ height: "100vh !important" }}
        zoomScale={0.3}
        maxZoom={1.5}
        fullscreenOnMobile={true}
        src={flow}
        zoomSrc={flow}
        fadeDuration={0}
        zoomSpeed={2}
        zoomPreload={false}
      />
    </Box>
  );
};

export default ZoomImageFile;
