import React, { useState } from 'react'
import { Box, TextField, Select, FormControl, MenuItem, InputLabel, Button, Grid, Stack, Divider, TextareaAutosize, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { RobotoTypo, ErrTypo } from '../../../utils/Typographies'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Hover1 } from '../../../assets/ContactUs/DateIcon.svg';
import { useTranslation } from 'react-i18next';
import { Grade } from '@mui/icons-material';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../pages/Admin/News/News.css';


export default function UniversityApplicationForm2({setIsFormValid, form2Errors, setForm2Errors, validationForm2, setFormPage,fatherDetails,setFatherDetails, motherDetails,setMotherDetils, spouseDetils,setSpouseDetails, sibilingDetails,setSibilingDetails, fundingDetails,setFundingDetails }) {
    // const [fatherDetails, setFatherDetails] = useState({name:"", nationality:"", dob:"", occupation:"", documents_held:"" })
    // const [motherDetails, setMotherDetils] = useState({name:"", nationality:"", dob:"", occupation:"", documents_held:""})
    // const [spouseDetils, setSpouseDetails] = useState({name:"", marraige_date:"", marraige_Regno:"", divorce_date:"", divorce_number:""})
    // const [sibilingDetails, setSibilingDetails] = useState({name:"", relationship:"", dob:"", nationality:"", occupation:"", documents_held:""})
    // const [fundingDetails, setFundingDetails] = useState({applicant_income:"", applicant_deposits:"", father_income:"", father_deposits:"", mother_income:"", mother_deposits:"", spouse_income:"", spouse_deposits:""})

    const { t } = useTranslation();
 
    const handleFatherDetails = (e,reqField) => {
        if(reqField == 'dob') {
            const formattedDate = moment(e).format('DD/MM/YYYY')
            setFatherDetails(prev => ({...prev, [reqField]: formattedDate}))
        } else {
            const {name, value} = e.target
            setFatherDetails(prev=> ({...prev, [name] : value}))
            setForm2Errors(prev=> ({...prev, fatherDetails: {...prev.fatherDetails, [name]: ""}}))
        }
    }

    const handleMotherDetails = (e,reqField) => {
        if(reqField == 'dob') {
            const formattedDate = moment(e).format('DD/MM/YYYY')
            setMotherDetils(prev => ({...prev, [reqField]: formattedDate}))
        } else {
            const {name, value} = e.target
            setMotherDetils(prev=> ({...prev, [name] : value}))
            setForm2Errors(prev=> ({...prev, motherDetails: {...prev.motherDetails, [name]: ""}}))
        }
    }

    const handleSpouseDetails = (e,reqField) => {
        if(reqField == 'marraige_date' || reqField == 'divorce_date') {
            const formattedDate = moment(e).format('DD/MM/YYYY')
            setSpouseDetails(prev=> ({...prev, [reqField] : formattedDate}))
        } else {
            const {name, value} = e.target
            setSpouseDetails(prev=> ({...prev, [name] : value}))
        }
    }

    const handleSibilingDetails = (e,reqField) => {
        if(reqField == 'dob') {
            const formattedDate = moment(e).format('DD/MM/YYYY')
            setSibilingDetails(prev => ({...prev, [reqField]: formattedDate}))
        } else {
            const {name, value} = e.target
            setSibilingDetails(prev=> ({...prev, [name] : value}))
        }
    }

    const handleFundingDetails = (e) => {
        const {name,value} = e.target
        setFundingDetails(prev=> ({...prev,[name] :value }))
        setForm2Errors(prev=> ({...prev, fundingDetails: {...prev.fundingDetails, [name]: ""}}))
    }

    const handleContinue = () => {
        // const isValid = validationForm2()
        // console.log('isValid ',isValid)
        // if(!isValid) {
            setIsFormValid(prev => ({...prev, formTwo: true}))
            setFormPage(2)
        // }

    }


    console.log('fundingDetails ',fundingDetails)
    return (
        <>
            <Box sx={{ backgroundColor: "#F7F7F7", margin: { md: '0 8%', xs: '0 2%' },  borderBottomLeftRadius: '20px', borderBottomRightRadius:'20px' }}>
                {/* <Box xs={12} padding='18px 30px' sx={{ backgroundColor: '#e1e1e1', borderRadius: '20px 20px 0px 0px' }}>
                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                        {t('ApplicationForm.father_info_title', { returnObjects: true })}
                    </RobotoTypo>
                </Box> */}
                <Divider
                    sx={{
                        borderTop: "1px solid #F3F4F6",
                        width: "100%",
                    }}
                />
                <Grid container rowGap={3} columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }} padding='3%'>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width='100%'
                    gap="10px"
                    px="16px"
                    sx={{
                        height: "66px",
                        backgroundColor: "#e1e1e1",
                        borderBottom: "1px solid #E5E7EB",
                        borderTop: "1px solid #E5E7EB",
                    }}
                    >
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.family_information.father_info_title', { returnObjects: true })}
                        </RobotoTypo>
                </Stack>
                     
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.name', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField name='name' value={fatherDetails.name} onChange={handleFatherDetails} />
                            {form2Errors?.fatherDetails?.name !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.fatherDetails?.name} </ErrTypo> </Box>}
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.nationality', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField name='nationality' value={fatherDetails.nationality} onChange={handleFatherDetails} />
                            {form2Errors?.fatherDetails?.nationality !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.fatherDetails?.nationality} </ErrTypo> </Box>}
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.dob', { returnObjects: true })}
                            </RobotoTypo>
                            <Box sx={{width:'100%', display:'flex',flex:1}}>
                            <DatePicker
                            dateFormat="dd/MM/yyyy"
                            style={{width:'100%'}}
                            selected={fatherDetails.dob ? moment(fatherDetails.dob, 'DD/MM/YYYY').toDate() : null}
                            onChange={(e) =>  handleFatherDetails(e, 'dob')}
                            placeholderText="DD/MM/YYYY"
                            customInput={<TextField name='dob' sx={{width:'100%'}} fullWidth />}
                            />
                        </Box>
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.occupation', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField name='occupation' value={fatherDetails.occupation} onChange={handleFatherDetails} />
                            {form2Errors?.fatherDetails?.occupation !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.fatherDetails?.occupation} </ErrTypo> </Box>}
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.documents_held', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField name='documents_held' value={fatherDetails.documents_held} onChange={handleFatherDetails} />
                        </Grid>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width='100%'
                            gap="10px"
                            px="16px"
                            sx={{
                                height: "66px",
                                backgroundColor: "#e1e1e1",
                                borderBottom: "1px solid #E5E7EB",
                                borderTop: "1px solid #E5E7EB",
                            }}
                        >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.mother_info_title', { returnObjects: true })}
                            </RobotoTypo>
                        </Stack>
                     
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.name', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField name='name' value={motherDetails.name} onChange={handleMotherDetails} />
                            {form2Errors?.motherDetails?.name !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.motherDetails?.name} </ErrTypo> </Box>}
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.nationality', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField name='nationality' value={motherDetails.nationality} onChange={handleMotherDetails} />
                            {form2Errors?.motherDetails?.nationality !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.motherDetails?.nationality} </ErrTypo> </Box>}
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.dob', { returnObjects: true })} 
                            </RobotoTypo>
                            <Box sx={{width:'100%', display:'flex',flex:1}}>
                            <DatePicker
                             dateFormat="dd/MM/yyyy"
                            style={{width:'100%'}}
                            selected={motherDetails.dob ? moment(motherDetails.dob, 'DD/MM/YYYY').toDate() : null}
                            onChange={(e) => handleMotherDetails(e, 'dob')}
                            placeholderText="DD/MM/YYYY"
                            customInput={<TextField name='dob' sx={{width:'100%'}} fullWidth />}
                            />
                        </Box>
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.occupation', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField name='occupation' value={motherDetails.occupation} onChange={handleMotherDetails}  />
                            {form2Errors?.motherDetails?.occupation !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.motherDetails?.occupation} </ErrTypo> </Box>}
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.family_information.documents_held', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField name='documents_held' value={motherDetails.documents_held} onChange={handleMotherDetails}  />
                        </Grid>

                    {/* Spouse Details */}
                    
                    <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width='100%'
                            gap="10px"
                            px="16px"
                            sx={{
                                height: "66px",
                                backgroundColor: "#e1e1e1",
                                borderBottom: "1px solid #E5E7EB",
                                borderTop: "1px solid #E5E7EB",
                            }}
                        >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.spouse_information.spouse_info_title', { returnObjects: true })}
                            </RobotoTypo>
                        </Stack>
                     
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.spouse_information.name', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField name='name' value={spouseDetils.name} onChange={handleSpouseDetails} />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.spouse_information.marriage_regno', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField name='marraige_Regno' value={spouseDetils.marraige_Regno} onChange={handleSpouseDetails}  />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.spouse_information.marriage_date', { returnObjects: true })}
                            </RobotoTypo>
                            <Box sx={{width:'100%', display:'flex',flex:1}}>
                            <DatePicker
                            style={{width:'100%'}}
                            dateFormat="dd/MM/yyyy"
                            selected={spouseDetils.marraige_date ? moment(spouseDetils.marraige_date, 'DD/MM/YYYY').toDate() : null}
                            onChange={(e) => handleSpouseDetails(e, 'marraige_date')}
                            placeholderText="DD/MM/YYYY"
                            customInput={<TextField name='dob' sx={{width:'100%'}} fullWidth />}
                            />
                        </Box>
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.spouse_information.divorce_date', { returnObjects: true })} 
                            </RobotoTypo>
                            <Box sx={{width:'100%', display:'flex',flex:1}}>
                            <DatePicker
                             dateFormat="dd/MM/yyyy"
                            style={{width:'100%'}}
                            selected={spouseDetils.divorce_date ? moment(spouseDetils.divorce_date, 'DD/MM/YYYY').toDate() : null}
                            onChange={(e) => handleSpouseDetails(e, 'divorce_date')}
                            placeholderText="DD/MM/YYYY"
                            customInput={<TextField name='divorce_date' sx={{width:'100%'}} fullWidth />}
                            />
                        </Box>
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.spouse_information.divorce_number', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField name='divorce_number' value={spouseDetils.divorce_number} onChange={handleSpouseDetails} />
                        </Grid>

                        {/* Sibiling Details */}
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width='100%'
                            gap="10px"
                            px="16px"
                            sx={{
                                height: "66px",
                                backgroundColor: "#e1e1e1",
                                borderBottom: "1px solid #E5E7EB",
                                borderTop: "1px solid #E5E7EB",
                            }}
                        >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.sibling_information.sibilng_info_title', { returnObjects: true })}
                            </RobotoTypo>
                        </Stack>
                     
                        <Grid xs={12} md={5} >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.sibling_information.name', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField  name='name' value={sibilingDetails.name} onChange={handleSibilingDetails} />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.sibling_information.relationship', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField  name='relationship' value={sibilingDetails.relationship} onChange={handleSibilingDetails} />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.sibling_information.dob', { returnObjects: true })} 
                            </RobotoTypo>
                            <Box sx={{width:'100%', display:'flex',flex:1}}>
                            <DatePicker
                            style={{width:'100%'}}
                            dateFormat="dd/MM/yyyy"
                            selected={sibilingDetails?.dob ? moment(sibilingDetails.dob, 'DD/MM/YYYY').toDate() : null}
                            onChange={(e) => handleSibilingDetails(e, 'dob')}
                            placeholderText="DD/MM/YYYY"
                            customInput={<TextField name='dob' sx={{width:'100%'}} fullWidth />}
                            />
                        </Box>
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.sibling_information.nationality', { returnObjects: true })}
                            </RobotoTypo>
                            <TextField  name='nationality' value={sibilingDetails.nationality} onChange={handleSibilingDetails} />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.sibling_information.occupation', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField  name='occupation' value={sibilingDetails.occupation} onChange={handleSibilingDetails} />
                        </Grid>
                        <Grid xs={12} md={5}>
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                {t('UniversityApplicationForm.sibling_information.documents_held', { returnObjects: true })} 
                            </RobotoTypo>
                            <TextField  name='documents_held' value={sibilingDetails.documents_held} onChange={handleSibilingDetails} />
                        </Grid>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width='100%'
                            gap="10px"
                            px="16px"
                            sx={{
                                height: "66px",
                                backgroundColor: "#e1e1e1",
                                borderBottom: "1px solid #E5E7EB",
                                borderTop: "1px solid #E5E7EB",
                            }}
                        >
                            <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '20px', textAlign: 'left' }}>
                            {t('UniversityApplicationForm.funding_information.funding_information_title', { returnObjects: true })}
                            </RobotoTypo>
                        </Stack>

                                <Grid md={12}>
                                    <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        1. {t('UniversityApplicationForm.funding_information.applicant', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                    </RobotoTypo>
                                </Grid>
                                    <Grid xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                        </RobotoTypo>
                                        <TextField name='applicant_income' value={fundingDetails.applicant_income} onChange={handleFundingDetails}/>
                                    </Grid>
                                    <Grid  xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                        </RobotoTypo>
                                        <TextField  name='applicant_deposits' value={fundingDetails.applicant_deposits} onChange={handleFundingDetails} />
                                    </Grid>

                                    <Grid md={12}>
                                    <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        2. {t('UniversityApplicationForm.funding_information.father', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                    </RobotoTypo>
                                </Grid>
                                    <Grid xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })} 
                                        </RobotoTypo> 
                                        <TextField  name='father_income' value={fundingDetails.father_income} onChange={handleFundingDetails} />
                                        {form2Errors?.fundingDetails?.father_income !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.fundingDetails?.father_income} </ErrTypo> </Box>}
                                    </Grid>
                                    <Grid  xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })} 
                                        </RobotoTypo>
                                        <TextField  name='father_deposits' value={fundingDetails.father_deposits} onChange={handleFundingDetails} />
                                    </Grid>

                                    <Grid md={12}>
                                    <RobotoTypo  sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        3. {t('UniversityApplicationForm.funding_information.mother', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                    </RobotoTypo>
                                </Grid>
                                    <Grid xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })} 
                                        </RobotoTypo>
                                        <TextField  name='mother_income' value={fundingDetails.mother_income} onChange={handleFundingDetails} />
                                        {form2Errors?.fundingDetails?.mother_income !='' && <Box sx={{ color: '#FF202E', display: 'inline-block' }}> <ErrTypo > {form2Errors?.fundingDetails?.mother_income} </ErrTypo> </Box>}
                                    </Grid>
                                    <Grid  xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                        </RobotoTypo>
                                        <TextField  name='mother_deposits' value={fundingDetails.mother_deposits} onChange={handleFundingDetails} />
                                    </Grid>

                                    <Grid md={12}>
                                    <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        4. {t('UniversityApplicationForm.funding_information.spouse', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                    </RobotoTypo>
                                </Grid>
                                    <Grid xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.monthly_income', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                        </RobotoTypo>
                                        <TextField  name='spouse_income' value={fundingDetails.spouse_income} onChange={handleFundingDetails} />
                                    </Grid>
                                    <Grid  xs={12} md={5}>
                                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                                        {t('UniversityApplicationForm.funding_information.deposits', { returnObjects: true })} <Box sx={{ color: '#FF202E', display: 'inline-block' }}></Box>
                                        </RobotoTypo>
                                        <TextField  name='spouse_deposits' value={fundingDetails.spouse_deposits} onChange={handleFundingDetails} />
                                    </Grid>

                </Grid>

                <Box display='flex' justifyContent="space-between" padding='2% 3%' sx={{ borderTop: "1px solid #E5E7EB", }}>
                    <Button onClick={() => { setFormPage(0) }} variant='Standard'>Back</Button>
                    <Button onClick={handleContinue} variant='Standard'>Continue</Button>
                </Box>
            </Box>
        </>
    )
}
