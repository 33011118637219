import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Divider,
  Select,
  IconButton,
  TextField,
  TableBody,
  TableCell,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Clear, Delete } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { getId } from "../../../utils/common";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../App.css";

const toolbarOptions = [
  ["bold", "italic", "underline", "link"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ color: [] }, { background: [] }],
];
const module = { toolbar: toolbarOptions };

const ckEditorConfig = {
  toolbar: [
    "bold",
    "italic",
    "underline",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "undo",
    "redo",
    "|",
    "table", // Include the table button in the toolbar
  ],
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"], // Additional table features in the toolbar
  },
};

export default function School() {
  const [schoolBanner, setSchoolBanner] = useState([]);
  const [schoolLogo, setSchoolLogo] = useState([]);
  const [multiValue, setMultiValue] = useState([]);
  console.log("multiValue ", multiValue);
  const [data, setData] = useState({
    schoolName_en: "",
    description_en: "",
    benefits_en: "",
    schoolName_ch: "",
    description_ch: "",
    youtube_url: "",
    benefits_ch: "",
    lat: "",
    lon: "",
    category_id: [],
    service_fee_name: "",
    service_fee_amount: "",
    Application_fee_name: "",
    Application_fee_amount: "",
    Trans_nota_service: "",
    Registration_fee: "",
    Preparatory_course_48hrs: "",
    Preparatory_course_24hrs: "",
    Internati_bank_remittance_charge: "",
    other_fee: "",
    other_name: "",
    application_title_en: "Application Fees",
    application_title_ch: "申請費",
  });
  const [dataError, setDataError] = useState({
    schoolName_en: "",
    description_en: "",
    benefits_en: "",
    schoolName_ch: "",
    description_ch: "",
    youtube_url: "",
    benefits_ch: "",
  });
  const [schoolFile, setSchoolFile] = useState([]);
  const [schoolLogoFile, setSchoolLogoFile] = useState([]);
  const [schoolImageUrl, setSchoolImageUrl] = useState("");
  const [schoolLogoUrl, setSchoolLogoUrl] = useState("");
  const [schoolPictures, setSchoolPictures] = useState([]);
  const [schoolPictureFiles, setSchoolPictureFiles] = useState([]);
  const [schoolPituresUrl, setSchoolPicturesUrl] = useState([]);
  const [fileError, setFileError] = useState("");
  let { schoolId, edit } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let schoolType = location?.pathname.replace("/admin/", "");
  schoolType = schoolType.split("/");
  const [fields, setFields] = useState([
    { title_en: "", title_ch: "", description_en: "", description_ch: "" },
  ]);
  const [partnerUniversities, setPartnerUniversities] = useState([
    {
      title_en: "",
      title_ch: "",
      description_en: "",
      description_ch: "",
      logo: [],
      logo_file: [],
      logo_url: "",
    },
  ]);
  const [admissionInfo, setAdmissionInfo] = useState([
    {
      admissionTitle_en: "",
      admissionTitle_ch: "",
      admissionDescription_en: "",
      admissionDescription_ch: "",
    },
  ]);
  const [entryRequirement, setEntryRequirement] = useState([
    {
      requirementTitle_en: "",
      requirementTitle_ch: "",
      requirementDescription_en: "",
      requirementDescription_ch: "",
    },
  ]);
  const [rows, setRows] = useState([[]]);
  const [rich, setRich] = useState({ fees_info_en: "", fees_info_ch: "" });
  console.log("schoolType ", schoolType);
  const handleChangeFeesEn = (event, editor) => {
    const data = editor.getData();
    setRich((prev) => {
      return { ...prev, fees_info_en: data };
    });
  };

  const handleChangeFeesCh = (event, editor) => {
    const data = editor.getData();
    setRich((prev) => {
      return { ...prev, fees_info_ch: data };
    });
  };

  useEffect(() => {
    if (rich?.length != 0) {
      console.log("ris ", rich);
    }
  }, [rich]);

  const addRow = () => {
    setRows([...rows, Array(rows[0].length).fill("")]);
  };

  const deleteRow = (index) => {
    if (rows?.length != 1) setRows(rows?.filter((row, i) => i !== index));
  };

  const addColumn = () => {
    setRows(rows?.map((row) => [...row, ""]));
  };

  const deleteColumn = (index) => {
    if (rows?.length != 1)
      setRows(rows?.map((row) => row.filter((cell, i) => i !== index)));
  };

  const handleAddField = () => {
    setFields([
      ...fields,
      { title_en: "", title_ch: "", description_en: "", description_ch: "" },
    ]);
  };

  const handleAdmissionInfoAddField = () => {
    setAdmissionInfo([
      ...admissionInfo,
      {
        admissionTitle_en: "",
        admissionTitle_ch: "",
        admissionDescription_en: "",
        admissionDescription_ch: "",
      },
    ]);
  };

  const handleRequirementAddField = () => {
    setEntryRequirement([
      ...entryRequirement,
      {
        requirementTitle_en: "",
        requirementTitle_ch: "",
        requirementDescription_en: "",
        requirementDescription_ch: "",
      },
    ]);
  };

  const handleAddPartnerUniversitiesField = () => {
    setPartnerUniversities([
      ...partnerUniversities,
      {
        title_en: "",
        title_ch: "",
        description_en: "",
        description_ch: "",
        logo: [],
        logo_url: "",
        logo_file: [],
      },
    ]);
  };

  const handleDeleteField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  const handleDeleteAdmissionInfo = (index) => {
    const newInfo = [...admissionInfo];
    newInfo.splice(index, 1);
    setAdmissionInfo(newInfo);
  };

  const handleDeletePartnerUniversities = (index) => {
    const newInfo = [...partnerUniversities];
    newInfo.splice(index, 1);
    setPartnerUniversities(newInfo);
  };

  const handleDeleteEntryRequirement = (index) => {
    const newRequirement = [...entryRequirement];
    newRequirement.splice(index, 1);
    setEntryRequirement(newRequirement);
  };

  const handleChange = (index, field, value) => {
    const newFields = [...fields];
    newFields[index][field] = value;
    setFields(newFields);
  };

  const handleAdmissionInfoChange = (index, info, value) => {
    const newinfo = [...admissionInfo];
    newinfo[index][info] = value;
    setAdmissionInfo(newinfo);
  };

  const handleEntryRequirementChange = (index, requirement, value) => {
    const newRequirement = [...entryRequirement];
    newRequirement[index][requirement] = value;
    setEntryRequirement(newRequirement);
  };

  const handleSchoolBanner = (e) => {
    const files = Array.from(e.target.files);

    const imagesArray = files.map((file) => URL.createObjectURL(file));
    setSchoolBanner((prevImages) => [...prevImages, ...imagesArray]);
    setSchoolBanner(imagesArray);
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "banner";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });
    setSchoolFile(modifiedFiles);
    setSchoolImageUrl("");
  };

  const handleRemoveImage = (index) => {
    setSchoolBanner((prevImages) => prevImages.filter((_, i) => i !== index));
    setSchoolFile([]);
  };

  const handleSchoolLogo = (e) => {
    const files = Array.from(e.target.files);
    console.log("files ", files);
    const imagesArray = files.map((file) => URL.createObjectURL(file));
    setSchoolLogo((prevImages) => [...prevImages, ...imagesArray]);
    setSchoolLogo(imagesArray);
    // setSchoolLogoFile(e.target.files[0])
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "logo";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });
    setSchoolLogoFile(modifiedFiles);
    setSchoolLogoUrl("");
  };

  const handleRemoveLogo = (index) => {
    setSchoolLogo((prevImages) => prevImages.filter((_, i) => i !== index));
    setSchoolLogoFile([]);
  };

  const handleRemovePartnerUniversityLogo = (universityIndex, logoIndex) => {
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      const updatedLogos = newPartnerUniversities[universityIndex].logo.filter(
        (_, i) => i !== logoIndex
      );
      const updatedLogoFiles = newPartnerUniversities[
        universityIndex
      ].logo_file.filter((_, i) => i !== logoIndex);
      console.log("updatedLogos ", updatedLogos);
      console.log("updatedLogoFiles ", updatedLogoFiles);
      newPartnerUniversities[universityIndex] = {
        ...newPartnerUniversities[universityIndex],
        logo: updatedLogos,
        logo_file: updatedLogoFiles,
      };
      return newPartnerUniversities;
    });
  };

  const handleRemovePartnerUniversityLogoUrl = (universityIndex, logoIndex) => {
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      newPartnerUniversities[universityIndex] = {
        ...newPartnerUniversities[universityIndex],
        logo_url: "",
      };
      return newPartnerUniversities;
    });
  };

  const handleSchoolPictures = (e) => {
    let files = Array.from(e.target.files);
    //  files.forEach((item,index) => {
    //     item.name = item.name+'$'+'school';
    //  })
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "school";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });
    setSchoolPictureFiles((prevFiles) => [...prevFiles, ...modifiedFiles]);

    const imagesArray = files.map((file) => {
      return {
        name: file.name, // Add file name
        url: URL.createObjectURL(file), // Blob URL
      };
    });

    setSchoolPictures((prevImages) => [...prevImages, ...imagesArray]);
  };

  const handleRemoveSchoolPictures = (name, index) => {
    setSchoolPictures((prevImages) => prevImages.filter((_, i) => i !== index));
    setSchoolPictureFiles((prevFiles) =>
      prevFiles.filter((file, i) => i !== index)
    );
  };

  const handleRemoveSchoolPicturesUrl = (imgs) => {
    setSchoolPicturesUrl((prev) => prev.filter((item) => item != imgs));
  };

  const handlePartnerUniversityLogo = (e, index) => {
    console.log("broken ", e);
    const files = Array.from(e.target.files);
    const imagesArray = files.map((file) => URL.createObjectURL(file));

    // Create a copy of the partnerUniversities array
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      // Update the logo field at the specific index
      newPartnerUniversities[index] = {
        ...newPartnerUniversities[index],
        logo: imagesArray,
      };
      console.log("newPartnerUniversities ", newPartnerUniversities);
      return newPartnerUniversities;
    });

    // Create modified files with new names
    const modifiedFiles = files.map((file) => {
      const newName = file.name + "$" + "logo";
      const modifiedBlob = new Blob([file], { type: file.type });
      return new File([modifiedBlob], newName, { type: file.type });
    });

    // Update the logo_file and logo_url fields
    setPartnerUniversities((prev) => {
      const newPartnerUniversities = [...prev];
      newPartnerUniversities[index] = {
        ...newPartnerUniversities[index],
        logo_file: modifiedFiles,
        logo_url: "",
      };
      return newPartnerUniversities;
    });

    // Reset the input field value
    e.target.value = null;
  };

  console.log("partnerUniversity ", partnerUniversities);
  const validate = () => {
    let error = false;
    if (data.title_en == "") {
      setDataError((prev) => ({ ...prev, title_en: "Title is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, title_en: "" }));
      error = false;
    }
    if (data.description_en == "") {
      setDataError((prev) => ({
        ...prev,
        description_en: "Description is required",
      }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, description_en: "" }));
      error = false;
    }
    if (data.title_ch == "") {
      setDataError((prev) => ({ ...prev, title_ch: "Title is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, title_ch: "" }));
      error = false;
    }
    if (data.description_ch == "") {
      setDataError((prev) => ({
        ...prev,
        description_ch: "Description is required",
      }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, description_ch: "" }));
      error = false;
    }
    if (data.url == "") {
      setDataError((prev) => ({ ...prev, url: "Url is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, url: "" }));
      error = false;
    }

    if (schoolImageUrl == "" && schoolFile?.length == 0) {
      setFileError("Please upload image");
      error = true;
    } else {
      setFileError("");
      error = false;
    }
    return error;
  };

  const handleSubmit = () => {
    //    const validation = validate()
    //    if(!validation) {
    const embeddURL = data.youtube_url;
    const form = new FormData();
    if (schoolImageUrl == "") {
      schoolFile.forEach((file) => {
        form.append("school_banner", file);
      });
    } else {
      form.append("school_banner", schoolImageUrl);
    }
    if (schoolLogoUrl == "") {
      schoolLogoFile.forEach((file) => {
        form.append("school_logo", file);
      });
    } else {
      form.append("school_logo", schoolLogoUrl);
    }
    if (schoolPictureFiles?.length != 0) {
      schoolPictureFiles.forEach((file) => {
        form.append("school_pictures", file);
      });
    }
    if (multiValue?.length != 0) {
      let a = [];
      multiValue.forEach((item) => a.push(item));
      console.log("type ", typeof a);
      form.append("category_id", JSON.stringify(a));
    }
    if (schoolPituresUrl?.length != 0 && schoolPituresUrl) {
      console.log("schoolPituresUrl ", schoolPituresUrl);
      form.append("school_pictures", JSON.stringify(schoolPituresUrl));
    }
    if (fields.length != 0) {
      form.append("courses", JSON.stringify(fields));
    }
    if (admissionInfo?.length != 0) {
      form.append("admission_info", JSON.stringify(admissionInfo));
    }
    if (entryRequirement?.length != 0) {
      form.append("entry_requirement", JSON.stringify(entryRequirement));
    }
    if (partnerUniversities?.length != 0) {
      form.append(
        "partnerUniversitiesLength",
        JSON.stringify(partnerUniversities)
      );
    }

    const dummyData = Array.from(
      { length: partnerUniversities.length },
      (_, index) => index + 1
    );
    console.log("typeof ", partnerUniversities.length);
    dummyData?.map((ite) => {
      console.log("ite ", ite);
    });
    // Append partner universities logos and other fields
    partnerUniversities.forEach((university, index) => {
      university.logo_file.forEach((file) => {
        form.append(`partnerUniversities[${index}][logo_file]`, file);
        console.log("filesssss ", file);
      });
      console.log("ddgdd ", university.logo_file);
      // form.append(`partnerUniversities[${index}][logo_file]`, university.logo_file);
      form.append(
        `partnerUniversities[${index}][title_en]`,
        university.title_en
      );
      form.append(
        `partnerUniversities[${index}][title_ch]`,
        university.title_ch
      );
      form.append(
        `partnerUniversities[${index}][description_en]`,
        university.description_en
      );
      form.append(
        `partnerUniversities[${index}][description_ch]`,
        university.description_ch
      );
      form.append(
        `partnerUniversities[${index}][logo_url]`,
        university.logo_url
      );
    });

    form.append("en_schoolName", data.schoolName_en);
    form.append("en_description", data.description_en);
    form.append("ch_schoolName", data.schoolName_ch);
    form.append("ch_description", data.description_ch);
    form.append("en_benefits", data.benefits_en);
    form.append("ch_benefits", data.benefits_ch);
    form.append("youtube_url", embeddURL);
    form.append("school_type", schoolType[0]);
    form.append("en_feesInfo", rich.fees_info_en);
    form.append("ch_feesInfo", rich.fees_info_ch);
    form.append("latitude", data.lat);
    form.append("longtitude", data.lon);

    form.append("service_fee_name", data.service_fee_name);
    form.append("service_fee_amount", data.service_fee_amount);
    form.append("Application_fee_name", data.Application_fee_name);
    form.append("Application_fee_amount", data.Application_fee_amount);
    form.append("Trans_nota_service", data.Trans_nota_service);
    form.append("Registration_fee", data.Registration_fee);
    form.append("Preparatory_course_48hrs", data.Preparatory_course_48hrs);
    form.append("Preparatory_course_24hrs", data.Preparatory_course_24hrs);
    form.append(
      "Internati_bank_remittance_charge",
      data.Internati_bank_remittance_charge
    );
    form.append("other_name", data.other_name);
    form.append("other_fee", data.other_fee);
    form.append("en_applicationName", data.application_title_en);
    form.append("ch_applicationName", data.application_title_ch);

    if (schoolId) {
      updateSchool(form);
    } else {
      addSchool(form);
    }

    //    }
  };
  console.log("datas", data);
  const addSchool = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addSchool`,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        // navigate('/admin/primarySchool')
        navigate(`/admin/${schoolType[0]}`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const mapDataToState = (data, dataCh) => {
    console.log("witihn map ", data, dataCh);
    return data.map((university, index) => ({
      title_en: university.title,
      title_ch: dataCh[index].title,
      description_en: university.description,
      description_ch: dataCh[index].description,
      logo: [],
      logo_file: [],
      logo_url: university.logo_url,
    }));
  };

  const getSchoolById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getSchool/` + id
      );
      if (response.status == 200) {
        const schoolData = response.data;
        console.log("schoolss ", schoolData);
        setData({
          schoolName_en: schoolData.en.school_name,
          description_en: schoolData.en.description,
          benefits_en: schoolData.en.benefits,
          schoolName_ch: schoolData.ch.school_name,
          description_ch: schoolData.ch.description,
          youtube_url: schoolData.en.url,
          benefits_ch: schoolData.ch.benefits,
          lat: schoolData.en.lat,
          lon: schoolData.en.lon,
          category_id: schoolData.en.category_id,
          service_fee_amount: schoolData.en.service_fee_amount,
          Application_fee_amount: schoolData.en.Application_fee_amount,
          Trans_nota_service: schoolData.en.Trans_nota_service,
          Registration_fee: schoolData.en.Registration_fee,
          Preparatory_course_48hrs: schoolData.en.Preparatory_course_48hrs,
          Preparatory_course_24hrs: schoolData.en.Preparatory_course_24hrs,
          Internati_bank_remittance_charge:
            schoolData.en.Internati_bank_remittance_charge,
          other_fee: schoolData.en.other_fee,
          application_title_en: schoolData.en.application_title,
          application_title_ch: schoolData.ch.application_title,
        });
        setSchoolImageUrl(schoolData.en.school_banner);
        setSchoolLogoUrl(schoolData.en.school_logo);
        setMultiValue(
            Array.isArray(schoolData.en.category_id) ? schoolData.en.category_id : []
        );
        setSchoolPicturesUrl(schoolData.en.school_pitures);
        setFields(
          schoolData.en.courses_info_adminpanel
            ? schoolData.en.courses_info_adminpanel
            : []
        );
        setAdmissionInfo(
          schoolData.en.admission_info_adminpanel
            ? schoolData.en.admission_info_adminpanel
            : []
        );
        setEntryRequirement(
          schoolData.en.entry_requirement_adminpanel
            ? schoolData.en.entry_requirement_adminpanel
            : []
        );
        setRich({
          fees_info_en: schoolData.en.fees_info,
          fees_info_ch: schoolData.ch.fees_info,
        });
        // setRows(schoolData.en.entry_requirement_adminpanel ? schoolData.en.entry_requirement_adminpanel : [])
        if (schoolData?.en?.partnerUniversity.length != 0) {
          const initialState = mapDataToState(
            schoolData.en.partnerUniversity,
            schoolData.ch.partnerUniversity
          );
          console.log("initialState ", initialState);
          setPartnerUniversities(initialState);
        }
      }
    } catch (error) {}
  };

  const updateSchool = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updateSchoolById/` + schoolId,
        reqData,
        {
          headers: {
            
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        console.log("school updated ", schoolType[0]);
        navigate(`/admin/${schoolType[0]}`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const handleInputChange = (e, reqName) => {
    if (reqName == "benefits_en" || reqName == "benefits_ch") {
      setData((prev) => ({ ...prev, [reqName]: e }));
      setDataError((prev) => ({ ...prev, [reqName]: "" }));
      return;
    }
    const { name, value } = e.target;
    if (
      name == "service_fee_amount" ||
      name == "Application_fee_amount" ||
      name == "Trans_nota_service" ||
      name == "Registration_fee" ||
      name == "Preparatory_course_48hrs" ||
      name == "Preparatory_course_24hrs" ||
      name == "Internati_bank_remittance_charge" ||
      name == "other_fee"
    ) {
      if (/^\d*$/.test(value)) {
        setData((prev) => ({ ...prev, [name]: value }));
        setDataError((prev) => ({ ...prev, [name]: "" }));
      }
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
      setDataError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleUniversityChange = (index, reqName, e) => {
    const newFields = [...partnerUniversities];
    newFields[index][reqName] = e;
    setPartnerUniversities(newFields);
  };

  useEffect(() => {
    if (schoolId) {
      getSchoolById(schoolId);
    }
  }, []);

  console.log("rows ", rows);
  return (
    <Admin>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              English
            </RobotoTypo>
            <RobotoTypo
              sx={{
                mt: 2,
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 2,
              }}
            >
              School Header
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              School Name
            </RobotoTypo>
            <TextField
              name="schoolName_en"
              value={data.schoolName_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.schoolName_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                Title
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 2,
              }}
            >
              Chinese
            </RobotoTypo>
            <RobotoTypo
              sx={{
                mt: 8,
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              School Name
            </RobotoTypo>
            <TextField
              name="schoolName_ch"
              value={data.schoolName_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.schoolName_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                Title
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload School picture
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="image-input"
            onChange={handleSchoolBanner}
          />
          <label htmlFor="image-input">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 1,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Image
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  mt: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "14px",
              color: "rgb(23, 125, 132)",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Image dimensions should be or below 1024 x 768 pixels.
          </RobotoTypo>
          <Grid container spacing={2}>
            {schoolImageUrl ? (
              <Grid item xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_FILE_URL}${schoolImageUrl}`}
                    alt={`Preview ${schoolImageUrl}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => setSchoolImageUrl("")}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ) : (
              schoolBanner?.map((image, index) => (
                <Grid item key={index} xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Preview ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload School Logo
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="logo-input"
            onChange={handleSchoolLogo}
          />
          <label htmlFor="logo-input">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Image
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "14px",
              color: "rgb(23, 125, 132)",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Image dimensions should be or below 250 x 100 pixels.
          </RobotoTypo>
          <Grid container spacing={2}>
            {schoolLogoUrl ? (
              <Grid item xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_FILE_URL}${schoolLogoUrl}`}
                    alt={`Preview ${schoolLogoUrl}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => setSchoolLogoUrl("")}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ) : (
              schoolLogo?.map((image, index) => (
                <Grid item key={index} xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Preview ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveLogo(index)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Description
            </RobotoTypo>
            <TextField
              name="description_en"
              value={data.description_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.description_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                description
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Youtube URL
            </RobotoTypo>
            <TextField
              name="youtube_url"
              value={data.youtube_url}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.youtube_url != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                url
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Description
            </RobotoTypo>
            <TextField
              name="description_ch"
              value={data.description_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.description_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                description
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Box sx={{ my: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Benefits and Advantages(English)
          </RobotoTypo>
          <ReactQuill
            value={data?.benefits_en}
            name="benefits_en"
            onChange={(e) => handleInputChange(e, "benefits_en")}
            modules={module}
            theme="snow"
            style={{ height: "150px" }}
            placeholder=""
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Benefits and Advantages(Chinese)
          </RobotoTypo>
          <ReactQuill
            value={data?.benefits_ch}
            name="benefits_ch"
            onChange={(e) => handleInputChange(e, "benefits_ch")}
            modules={module}
            theme="snow"
            style={{ height: "150px" }}
            placeholder=""
          />
        </Box>

        <Box sx={{ my: 2 }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload School Pictures
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="school-pictures"
            multiple
            onChange={handleSchoolPictures}
          />
          <label htmlFor="school-pictures">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Images
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  mt: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "14px",
              color: "rgb(23, 125, 132)",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Image dimensions should be 500 X 500 pixels or have a 4:4 aspect
            ratio to fit optimizely.
          </RobotoTypo>
          <Grid container spacing={2}>
            {schoolPituresUrl?.length != 0 &&
              schoolPituresUrl?.map((image, index) => (
                <Grid item xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={`${process.env.REACT_APP_FILE_URL}${image}`}
                      alt={`Preview ${image}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveSchoolPicturesUrl(image)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))}

            {schoolPictures?.map((image, index) => (
              <Grid item key={index} xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={image.url}
                    alt={`Preview ${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() =>
                      handleRemoveSchoolPictures(image.name, index)
                    }
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ my: 2 }}>
          <Box>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 22 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Major/Courses
            </RobotoTypo>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={handleAddField}
            >
              Add
            </Button>
            {fields?.map((field, index) => (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                key={index}
              >
                <Box
                  sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                >
                  {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Courses Title(English)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={field.title_en}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleChange(index, "title_en", e.target.value)
                      }
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Courses Title(Chinese)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={field.title_ch}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleChange(index, "title_ch", e.target.value)
                      }
                    />
                  </Box>
                  {/* </Box> */}
                </Box>
                <Box sx={{ my: 2 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Description(English)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_en}
                    onChange={(e) => handleChange(index, "description_en", e)}
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Description(Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={field?.description_ch}
                    onChange={(e) => handleChange(index, "description_ch", e)}
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                </Box>
                <Box sx={{ mt: 4 }}>
                  {/* <IconButton > */}
                  {/* <Delete /> */}
                  <Button
                    component="span"
                    onClick={() => handleDeleteField(index)}
                    aria-label="delete"
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      mt: 2,
                      width: "fit-content",
                      backgroundColor: "#f50057",
                      "&:hover": { backgroundColor: "#f50057" },
                    }}
                  >
                    Delete
                  </Button>
                  {/* </IconButton> */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Patnership universities */}
        {schoolType[0] == "university" && (
          <Box sx={{ my: 2 }}>
            <Box>
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 18, sm: 22 },
                  color: "#003B5C",
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                Partner Universities
              </RobotoTypo>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#177D84",
                  "&:hover": { backgroundColor: "#177D84" },
                }}
                onClick={handleAddPartnerUniversitiesField}
              >
                Add
              </Button>
              {partnerUniversities?.map((field, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  key={index}
                >
                  {console.log("field ", field)}
                  <Box
                    sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                  >
                    {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                    <Box sx={{ width: "100%" }}>
                      <RobotoTypo
                        sx={{
                          textAlign: "center",
                          width: "fit-content",
                          fontSize: { xs: 18, sm: 18 },
                          color: "#003B5C",
                          fontWeight: 700,
                          mb: 1,
                        }}
                      >
                        University Title(English)
                      </RobotoTypo>
                      <TextField
                        variant="outlined"
                        value={field.title_en}
                        sx={{ width: "100%" }}
                        onChange={(e) =>
                          handleUniversityChange(
                            index,
                            "title_en",
                            e.target.value
                          )
                        }
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <RobotoTypo
                        sx={{
                          textAlign: "center",
                          width: "fit-content",
                          fontSize: { xs: 18, sm: 18 },
                          color: "#003B5C",
                          fontWeight: 700,
                          mb: 1,
                        }}
                      >
                        University Title(Chinese)
                      </RobotoTypo>
                      <TextField
                        variant="outlined"
                        value={field.title_ch}
                        sx={{ width: "100%" }}
                        onChange={(e) =>
                          handleUniversityChange(
                            index,
                            "title_ch",
                            e.target.value
                          )
                        }
                      />
                    </Box>
                    {/* </Box> */}
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 2,
                      }}
                    >
                      Description(English)
                    </RobotoTypo>
                    <ReactQuill
                      value={field?.description_en}
                      onChange={(e) =>
                        handleUniversityChange(index, "description_en", e)
                      }
                      modules={module}
                      theme="snow"
                      style={{ height: "150px" }}
                      placeholder=""
                    />
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mt: 8,
                        mb: 2,
                      }}
                    >
                      Description(Chinese)
                    </RobotoTypo>
                    <ReactQuill
                      value={field?.description_ch}
                      onChange={(e) =>
                        handleUniversityChange(index, "description_ch", e)
                      }
                      modules={module}
                      theme="snow"
                      style={{ height: "150px" }}
                      placeholder=""
                    />
                  </Box>

                  <Box sx={{ mb: 2, mt: 5 }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Upload University Logo
                    </RobotoTypo>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id={`logo-inputs${index}`}
                      onChange={(e) => handlePartnerUniversityLogo(e, index)}
                    />
                    <label htmlFor={`logo-inputs${index}`}>
                      <Button
                        component="span"
                        variant="contained"
                        sx={{
                          mb: 2,
                          backgroundColor: "#177D84",
                          "&:hover": { backgroundColor: "#177D84" },
                        }}
                      >
                        Select Images
                      </Button>
                      {fileError != "" && (
                        <RobotoTypo
                          sx={{
                            textAlign: "center",
                            width: "fit-content",
                            fontSize: { xs: 14, sm: 18 },
                            color: "red",
                            fontWeight: 500,
                            my: 1,
                          }}
                        >
                          {fileError}
                        </RobotoTypo>
                      )}
                    </label>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: "14px",
                        color: "rgb(23, 125, 132)",
                        fontWeight: 700,
                        mb: 2,
                      }}
                    >
                      Image dimensions should be or below 250 x 100 pixels.
                    </RobotoTypo>
                    <Grid container spacing={2}>
                      {field.logo_url ? (
                        <Grid item xs={6} md={4}>
                          <Box style={{ position: "relative" }}>
                            <Box
                              component="img"
                              src={`${process.env.REACT_APP_FILE_URL}${field.logo_url}`}
                              alt={`Preview ${field.logo_url}`}
                              style={{ width: "100%", height: "auto" }}
                            />
                            <IconButton
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={() =>
                                handleRemovePartnerUniversityLogoUrl(index)
                              }
                            >
                              <Clear />
                            </IconButton>
                          </Box>
                        </Grid>
                      ) : (
                        field.logo?.map((image, logoIndex) => (
                          <Grid item key={index} xs={6} md={4}>
                            <Box style={{ position: "relative" }}>
                              <Box
                                component="img"
                                src={image}
                                alt={`Preview ${index}`}
                                style={{ width: "100%", height: "auto" }}
                              />
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() =>
                                  handleRemovePartnerUniversityLogo(
                                    index,
                                    logoIndex
                                  )
                                }
                              >
                                <Clear />
                              </IconButton>
                            </Box>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Box>

                  <Box sx={{ mt: 4 }}>
                    {/* <IconButton > */}
                    {/* <Delete /> */}
                    <Button
                      component="span"
                      onClick={() => handleDeletePartnerUniversities(index)}
                      aria-label="delete"
                      variant="contained"
                      sx={{
                        alignSelf: "center",
                        mt: 2,
                        width: "fit-content",
                        backgroundColor: "#f50057",
                        "&:hover": { backgroundColor: "#f50057" },
                      }}
                    >
                      Delete
                    </Button>
                    {/* </IconButton> */}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box sx={{ my: 2 }}>
          <Box>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 22 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Admission Info
            </RobotoTypo>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={handleAdmissionInfoAddField}
            >
              Add
            </Button>
            {admissionInfo?.map((info, index) => (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                key={index}
              >
                <Box
                  sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                >
                  {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Admission Information Title(English)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={info.admissionTitle_en}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleAdmissionInfoChange(
                          index,
                          "admissionTitle_en",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Admission Information Title(Chinese)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={info.admissionTitle_ch}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleAdmissionInfoChange(
                          index,
                          "admissionTitle_ch",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  {/* </Box> */}
                </Box>
                <Box sx={{ my: 2 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Admission Information Description(English)
                  </RobotoTypo>
                  <ReactQuill
                    value={info?.admissionDescription_en}
                    onChange={(e) =>
                      handleAdmissionInfoChange(
                        index,
                        "admissionDescription_en",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Admission Information Description(Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={info?.admissionDescription_ch}
                    onChange={(e) =>
                      handleAdmissionInfoChange(
                        index,
                        "admissionDescription_ch",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                </Box>
                <Box sx={{ mt: 4 }}>
                  {/* <IconButton > */}
                  {/* <Delete /> */}
                  <Button
                    component="span"
                    onClick={() => handleDeleteAdmissionInfo(index)}
                    aria-label="delete"
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      mt: 2,
                      width: "fit-content",
                      backgroundColor: "#f50057",
                      "&:hover": { backgroundColor: "#f50057" },
                    }}
                  >
                    Delete
                  </Button>
                  {/* </IconButton> */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ my: 2 }}>
          <Box>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 22 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Entry Requirements
            </RobotoTypo>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
              onClick={handleRequirementAddField}
            >
              Add
            </Button>
            {entryRequirement?.map((requirement, index) => (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                key={index}
              >
                <Box
                  sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}
                >
                  {/* <Box sx={{display:'flex', gap:'20px',width:'100%'}}> */}
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Entry Requirement Title(English)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={requirement.requirementTitle_en}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleEntryRequirementChange(
                          index,
                          "requirementTitle_en",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <RobotoTypo
                      sx={{
                        textAlign: "center",
                        width: "fit-content",
                        fontSize: { xs: 18, sm: 18 },
                        color: "#003B5C",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Entry Requirement Title(Chinese)
                    </RobotoTypo>
                    <TextField
                      variant="outlined"
                      value={requirement.requirementTitle_ch}
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        handleEntryRequirementChange(
                          index,
                          "requirementTitle_ch",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  {/* </Box> */}
                </Box>
                <Box sx={{ my: 2 }}>
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Entry Requirement(English)
                  </RobotoTypo>
                  <ReactQuill
                    value={requirement.requirementDescription_en}
                    onChange={(e) =>
                      handleEntryRequirementChange(
                        index,
                        "requirementDescription_en",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                  <RobotoTypo
                    sx={{
                      textAlign: "center",
                      width: "fit-content",
                      fontSize: { xs: 18, sm: 18 },
                      color: "#003B5C",
                      fontWeight: 700,
                      mt: 8,
                      mb: 2,
                    }}
                  >
                    Entry Requirement(Chinese)
                  </RobotoTypo>
                  <ReactQuill
                    value={requirement.requirementDescription_ch}
                    onChange={(e) =>
                      handleEntryRequirementChange(
                        index,
                        "requirementDescription_ch",
                        e
                      )
                    }
                    modules={module}
                    theme="snow"
                    style={{ height: "150px" }}
                    placeholder=""
                  />
                </Box>
                <Box sx={{ mt: 4 }}>
                  {/* <IconButton > */}
                  {/* <Delete /> */}
                  <Button
                    component="span"
                    onClick={() => handleDeleteEntryRequirement(index)}
                    aria-label="delete"
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      mt: 2,
                      width: "fit-content",
                      backgroundColor: "#f50057",
                      "&:hover": { backgroundColor: "#f50057" },
                    }}
                  >
                    Delete
                  </Button>
                  {/* </IconButton> */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {/* <Box sx={{width:'100%',overflow:'auto'}}>
            <Button onClick={addRow}>Add Row</Button>
                <Button onClick={addColumn}>Add Column</Button>
                <TableContainer component={Paper}>
                    <Table>
                    <TableHead>
                        <TableRow>
                        {rows[0].map((cell, index) => (
                            <TableCell key={index}>
                            <Button onClick={() => deleteColumn(index)}>Delete</Button>
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {row.map((cell, columnIndex) => (
                            <TableCell key={columnIndex}>
                                <input
                                type="text"
                                value={cell}
                                onChange={(e) => {
                                    const updatedRows = [...rows];
                                    updatedRows[rowIndex][columnIndex] = e.target.value;
                                    setRows(updatedRows);
                                }}
                                />
                            </TableCell>
                            ))}
                            <TableCell>
                            <Button onClick={() => deleteRow(rowIndex)}>Delete</Button>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Box> */}
        {/* {value && <Slate editor={editor} value={value ? value : ''} onChange={newValue => setValue(newValue)}>
      <Editable />
    </Slate>} */}
        <Box sx={{ my: 2 }}>
          {console.log("rich ", rich)}
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "18px",
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Tuition Fees Introduction(Engish)
          </RobotoTypo>
          <CKEditor
            editor={ClassicEditor}
            data={
              rich?.fees_info_en && rich?.fees_info_en == ""
                ? ""
                : rich?.fees_info_en
            }
            onChange={handleChangeFeesEn}
          />
          {
            <p
              dangerouslySetInnerHTML={{
                __html:
                  rich?.fees_info_en && rich?.fees_info_en == ""
                    ? ""
                    : rich?.fees_info_en,
              }}
            />
          }
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: "18px",
              color: "#003B5C",
              fontWeight: 700,
              mb: 2,
            }}
          >
            Tuition Fees Introduction(Chinese)
          </RobotoTypo>
          <CKEditor
            editor={ClassicEditor}
            data={
              rich?.fees_info_ch && rich?.fees_info_ch == ""
                ? ""
                : rich?.fees_info_ch
            }
            onChange={handleChangeFeesCh}
          />
          {
            <p
              dangerouslySetInnerHTML={{
                __html:
                  rich?.fees_info_ch && rich?.fees_info_ch == ""
                    ? ""
                    : rich?.fees_info_ch,
              }}
            />
          }
        </Box>
        <Box sx={{ my: 2, display: "flex", gap: "20px", width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Latitude
            </RobotoTypo>
            <TextField
              name="lat"
              value={data.lat}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 18 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Longtitude
            </RobotoTypo>
            <TextField
              name="lon"
              value={data.lon}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box mb={1}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",

              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Category
          </RobotoTypo>
          <Select
            name="category_id"
            value={multiValue}
            multiple
            onChange={(e) => {
              setMultiValue(e.target.value);
            }}
            sx={{ width: "300px" }}
          >
            <MenuItem value={1}>psychology</MenuItem>
            <MenuItem value={2}>mass-media-communication</MenuItem>
            <MenuItem value={3}>information-engineer</MenuItem>
            <MenuItem value={4}>art-design</MenuItem>
            <MenuItem value={5}>business-marketing</MenuItem>
            <MenuItem value={6}>medicine-biology</MenuItem>
          </Select>
        </Box>

        {/* Fees Structure */}
        <Divider />
        <Grid
          container
          rowGap={3}
          columnGap={{ md: 14, sm: 13, lg: 19, xl: 24 }}
        >
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 18 },
              color: "#003B5C",
              fontWeight: 700,
            }}
          >
            Fee Structure
          </RobotoTypo>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <RobotoTypo
                mr={1}
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                Title For Application Page (English)
              </RobotoTypo>
              <TextField
                sx={{ width: "90%" }}
                onChange={handleInputChange}
                value={
                  data?.application_title_en != "undefined" &&
                  data?.application_title_en != ""
                    ? data?.application_title_en
                    : ""
                }
                name="application_title_en"
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <RobotoTypo
                mr={1}
                sx={{
                  fontWeight: "500",
                  color: "#003B5C",
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                Title For Application Page (Chinese)
              </RobotoTypo>
              <TextField
                sx={{ width: "90%" }}
                onChange={handleInputChange}
                value={
                  data?.application_title_ch != "undefined" &&
                  data?.application_title_ch != ""
                    ? data?.application_title_ch
                    : ""
                }
                name="application_title_ch"
              />
            </Box>
          </Box>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Service fee for applying
            </RobotoTypo>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.service_fee_name != "undefined" &&
                data?.service_fee_name != ""
                  ? data?.service_fee_name
                  : ""
              }
              name="service_fee_name"
            />
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.service_fee_amount != "undefined" &&
                data?.service_fee_amount != ""
                  ? data?.service_fee_amount
                  : ""
              }
              name="service_fee_amount"
            />
          </Grid>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Application fee for
            </RobotoTypo>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.Application_fee_name != "undefined" &&
                data?.Application_fee_name != ""
                  ? data?.Application_fee_name
                  : ""
              }
              name="Application_fee_name"
            />
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.Application_fee_amount != "undefined" &&
                data?.Application_fee_amount != ""
                  ? data?.Application_fee_amount
                  : ""
              }
              name="Application_fee_amount"
            />
          </Grid>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Translation and notarization service
            </RobotoTypo>
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.Trans_nota_service != "undefined" &&
                data?.Trans_nota_service != ""
                  ? data?.Trans_nota_service
                  : ""
              }
              name="Trans_nota_service"
            />
          </Grid>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Registration fee for preparatory course for admission to
              international school package (one-time payment)
            </RobotoTypo>
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.Registration_fee != "undefined" &&
                data?.Registration_fee != ""
                  ? data?.Registration_fee
                  : ""
              }
              name="Registration_fee"
            />
          </Grid>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Preparatory course for admission to international schools (48
              hours 1 to 1)
            </RobotoTypo>
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.Preparatory_course_48hrs != "undefined" &&
                data?.Preparatory_course_48hrs != ""
                  ? data?.Preparatory_course_48hrs
                  : ""
              }
              name="Preparatory_course_48hrs"
            />
          </Grid>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Preparatory course for admission to international schools (24
              hours 1 to 1)
            </RobotoTypo>
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.Preparatory_course_24hrs != "undefined" &&
                data?.Preparatory_course_24hrs != ""
                  ? data?.Preparatory_course_24hrs
                  : ""
              }
              name="Preparatory_course_24hrs"
            />
          </Grid>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              International bank remittance charge
            </RobotoTypo>
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.Internati_bank_remittance_charge != "undefined" &&
                data?.Internati_bank_remittance_charge != ""
                  ? data?.Internati_bank_remittance_charge
                  : ""
              }
              name="Internati_bank_remittance_charge"
            />
          </Grid>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <RobotoTypo
              mr={1}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              other:
            </RobotoTypo>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.other_name != "undefined" && data?.other_name != ""
                  ? data?.other_name
                  : ""
              }
              name="other_name"
            />
            <Box mx={1} sx={{ display: "inline-block" }}>
              : $
            </Box>
            <TextField
              sx={{ width: "200px" }}
              onChange={handleInputChange}
              value={
                data?.other_fee != "undefined" && data?.other_fee != ""
                  ? data?.other_fee
                  : ""
              }
              name="other_fee"
            />
          </Grid>
        </Grid>

        <Button
          component="span"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 2,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          {schoolId ? "Edit" : "Add"}
        </Button>
      </Box>
    </Admin>
  );
}
