import { ReactComponent as EmailIcon } from "../assets/svg/Email.svg";
import { ReactComponent as LocationIcon } from "../assets/svg/Location.svg";
import { ReactComponent as PhoneIcon } from "../assets/svg/Phone.svg";
import { ReactComponent as Company } from "../assets/svg/Company.svg";
import WhatsappQr from "../assets/png/WhatsappQr.png";
import LineQr from "../assets/png/LineQr.jpeg";
import WeChatQr from "../assets/png/WeChatQr.png";
import { Box } from "@mui/material";

const IconWrapper = ({ children }) => (
  <Box sx={{ height: { xs: 28, sm: 40 }, width: { xs: 28, sm: 40 } }}>
    {" "}
    {children}
  </Box>
);

export const FooterInfo = [
  {
    Icon: (
      <IconWrapper>
        <LocationIcon style={{ height: "inherit", width: "inherit" }} />
      </IconWrapper>
    ),
    data: "No 99 Rangoon Road, Singapore 218384",
  },
  {
    Icon: (
      <IconWrapper>
        <EmailIcon style={{ height: "inherit", width: "inherit" }} />
      </IconWrapper>
    ),
    data: "info@dredugroup.com",
    type: "email",
  },
  {
    Icon: (
      <IconWrapper>
        <PhoneIcon style={{ height: "inherit", width: "inherit" }} />
      </IconWrapper>
    ),
    data: "+65 6962 6868 / +65 6677 6789",
  },
];

export const CompanyIcon = {
  Icon: <Company style={{ height: "inherit", width: "inherit" }} />,
};

export const Qrs = [
  { title: "Official Line Account", image: LineQr },
  { title: "Official WeChat Account", image: WeChatQr },
  { title: "Official WhatsApp Account", image: WhatsappQr },
];

export const FooterFeaturesData = [
  {
    title: "Services",
    data: [
      "Pass Application & Certification",
      "Studying Abroad",
      "Agent Representative & Consultation",
      "Course & Tuition",
      "Activities & Travelling",
    ],
  },
  {
    title: "Studying Abroad",
    data: [
      "Primary School",
      "Secondary and High School",
      "International School",
      "University",
    ],
  },
  {
    title: "Related Website",
    data: ["Immigration Website"],
  },
];
