import { ReactComponent as School } from '../assets/Services/schoolBased.svg';
import { ReactComponent as Endrollment } from '../assets/Services/endrollment.svg';
import { ReactComponent as Gaurdian } from '../assets/Services/gaurdian.svg';
import { ReactComponent as ExtracurricularActivity } from '../assets/Services/extracurricular.svg';
import { ReactComponent as Tution } from '../assets/Services/tution.svg';
import { ReactComponent as Visa } from '../assets/Services/visa.svg';
import { ReactComponent as PickUp } from '../assets/Services/pickup.svg';
import { ReactComponent as Accomodation } from '../assets/Services/accomodation.svg';
import { ReactComponent as Camp } from '../assets/Services/camp.svg';
import { ReactComponent as Academic } from '../assets/Services/academic.svg';
import { ReactComponent as Job } from '../assets/Services/jobOpportunity.svg';
import { ReactComponent as Competition } from '../assets/Services/competition.svg';

export const ServiceIcons = [
    {
        icon: <School />,
    },
    {
        icon: <Endrollment />,
    },
    {
        icon: <Gaurdian />,
    },
    {
        icon: <ExtracurricularActivity />,
    },
    {
        icon: <Tution />,
    },
    {
        icon: <Visa />,
    },
    {
        icon: <PickUp />,
    },
    {
        icon: <Accomodation />,
    },
    {
        icon: <Camp />,
    },
    {
        icon: <Academic />
    },
    {
        icon: <Job />,
    },
    {
        icon: <Competition/>,
    },
]