import React from "react";
import { Box } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as Circle } from "../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";

export default function VisaDocuments() {
  const { t } = useTranslation();

  return (
    <Box sx={{ position: "relative", background: "#B1D0E0" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            borderBottomRightRadius: {
              xs: "0",
              md: "0",
              lg: "0",
              xl: "50px",
              xxl: "50px",
            },
            // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
            padding: "40px 0",
            zIndex: 10000,
            height: { xs: "11%", sm: "11%", md: "11%", lg: "15%", xl: "15%" },
            width: { xs: "100%", sm: "100%", md: "100%", lg: "30%", xl: "30%" },
            position: {
              xs: "relative",
              sm: "relative",
              md: "relative",
              lg: "absolute",
              xl: "absolute",
            },
            left: "0",
            top: "0",
            background: "#F5DFBA",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
          <Box
            // key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <RobotoTypo
              sx={{
                fontWeight: "700",
                color: "#003B5C",
                fontSize: { xs: "22px", sm: "25px", md: "28px", lg: "30px" },
                textAlign: "center",
              }}
            >
              {
                t("service.travelVisa.documents", {
                  returnObjects: true,
                }).title1
              }{" "}
            </RobotoTypo>
            <TimesNewTypo
              sx={{
                fontWeight: "700",
                color: "#177D84",
                fontSize: { xs: "18px", md: "22px", lg: "24px" },
                textAlign: "center",
              }}
            >
              {
                t("service.travelVisa.documents", {
                  returnObjects: true,
                }).title2
              }
            </TimesNewTypo>
          </Box>
          {/* ))} */}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: "40px",
            padding: { xs: "50px 0", md: "110px 0" },
          }}
        >
          <Box
            sx={{
              zIndex: "10000",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              gap: "20px",
              width: "50%",
              padding: "50px 30px",
              backgroundColor: "white",
              borderRadius: "50px",
            }}
          >
            {t("service.travelVisa.documents.bullets", {
              returnObjects: true,
            }).map((item, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Box>
                  <Circle />
                </Box>
                <RobotoTypo
                  sx={{
                    fontWeight: "400",
                    letterSpacing: "0.03em",
                    lineHeight: "25px",
                    color: "#242424",
                    fontSize: { xs: "16px", md: "16px", lg: "16px" },
                    textAlign: "left",
                  }}
                >
                  {item}
                </RobotoTypo>
              </Box>
            ))}
            <RobotoTypo
              sx={{
                fontWeight: "400",
                letterSpacing: "0.03em",
                lineHeight: "25px",
                color: "#242424",
                fontSize: { xs: "16px", md: "16px", lg: "16px" },
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              
              {
                t("service.travelVisa", {
                  returnObjects: true,
                }).note
              }
            </RobotoTypo>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
